import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/service/admin.service';
import { InfoService } from 'src/app/service/info.service';
import { User } from 'src/app/model/user';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NotificationService } from 'src/app/service/notification.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/user.service';
import { ProfileTitle } from '../../enum/profile.title';
import { ActingArea } from '../../enum/acting.area';
import { TranslateService } from '@ngx-translate/core';
import { Country } from 'src/app/model/country';
import { Affiliation } from 'src/app/model/affiliation';
import { SystemService } from 'src/app/service/system.service';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateAffiliationComponent } from 'src/app/component/create-affiliation/create-affiliation.component';
import { InputFilterAutocompleteComponent } from 'src/app/component/input-filter-autocomplete/input-filter-autocomplete.component';
import _ from 'lodash';
import { plainToClass } from 'class-transformer';
import { SelectOption } from '../../model/select.option';

@Component({
  selector: 'app-admin-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class AdminUserSearchComponent implements OnInit {

  searchForm: FormGroup;
  editForm: FormGroup;
  passForm: FormGroup;
  showForms: boolean = false;

  profileTitles: Array<SelectOption>;
  actingAreas: Array<SelectOption>;

  selfUser: User;

  countryList: Array<Country>;

  private newAffiliationName: string;
  private newAffiliationAcronym: string;
  public allAffiliations: Affiliation[];
  public modelAffiliation = { dataBase: 'filter', value_1: 'filter' };
  private newAffiliationWasCreated: boolean = false;

  @ViewChild(CreateAffiliationComponent) createAffiliation: CreateAffiliationComponent;
  @ViewChild(InputFilterAutocompleteComponent) inputFilterAutocomplete: InputFilterAutocompleteComponent;

  constructor(
    private adminService: AdminService,
    private infoService: InfoService,
    private notificationService: NotificationService,
    private userService: UserService,
    public systemService: SystemService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog
  ) { 
    this.profileTitles = ProfileTitle.toSelectable();
    this.actingAreas = ActingArea.toSelectable();
  }

  ngOnInit() {
    this.userService.getUserFromToken().subscribe(user => this.selfUser = user);
    this.initSearchForm();
    this.initCountryList();
    this.loadAffiliations(true);
  }

  private initCountryList(): void {
    this.countryList = new Array;
    this.countryList.push(new Country);
  }

  private initSearchForm(): void {
    this.searchForm = this.fb.group({
      users: []
    });

    this.searchForm.get('users').valueChanges.subscribe(() => {
      this.showForms = false;
    });
  }

  private initEditForm(): void {
    const affiliation = this.initAffiliationForm();
    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    this.userService.getUser(this.searchUser.id).subscribe(user => {
      this.editForm = this.fb.group({
        id: [user.id, [Validators.required]],
        firstName: [user.firstName, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
        lastName: [user.lastName, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
        email: [user.email, [Validators.required, Validators.email]],
        profile: this.fb.group({
          profileTitle: [user.profile.profileTitle],
          actingArea: [user.profile.actingArea],
          country: [user.profile.country, [Validators.required]],
          firstAlternativeEmail: [user.profile.firstAlternativeEmail, [Validators.email, Validators.maxLength(100)]],
          secondAlternativeEmail: [user.profile.secondAlternativeEmail, [Validators.email, Validators.maxLength(100)]],
          shortBio: [user.profile.shortBio, [Validators.maxLength(1000)]],
          publicPageURL: [user.profile.publicPageUrl, [
            Validators.pattern(urlRegex), Validators.maxLength(200)
          ]],
          phoneNumber: [user.profile.phoneNumber],
          affiliation: [affiliation, [Validators.required]],
          orcid: [user.profile.orcid, [
            Validators.pattern(/^([0-9]{4})?(-)?([0-9]{4})?(-)?([0-9]{4})?(-)?([0-9X]{4})$/), Validators.minLength(16), Validators.maxLength(19)
          ]],
          lattes: [user.profile.lattes, [
            Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.minLength(16), Validators.maxLength(16)
          ]]
        })
      });
    });
  }

  private initPasswordForm(): void {
    this.passForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  setProxy(user: User) {

    this.infoService.clearProxy();

    this.adminService.getProxyUser(user.id).subscribe(response => {
      this.infoService.setProxyUser(response);
      this.notificationService.notify('admin.user-proxy.proxy-active', { params: { user }});
      this.router.navigate(['/']);
    });
  }

  blockProxy(user: User) : boolean {
    return (user.id == this.selfUser.id) || this.infoService.proxyActive || !user.isActive;
  }

  getBlockProxyError(user: User): string {
    if (!this.blockProxy(user)) return '';

    let errorMessage = 'inactive-user';

    if (this.infoService.proxyActive) {
      errorMessage = 'already-active';
    } else if (user.id == this.selfUser.id) {
      errorMessage = 'own-user';
    }

    return this.translateService.instant(`admin.user-proxy.error.${errorMessage}-message`);
  }

  public loadAffiliations(load: boolean): void {
    if (load) {
      this.systemService.getAllAffiliations().pipe(map(res => res.data))
        .subscribe((Affiliations: Affiliation[]) => this.allAffiliations = [...Affiliations]);
    }
  }

  private buildStringAffiliation(acronym: string, name: string): string {
    if (!!name && !!acronym) {
      return `${acronym}, ${name}`;
    } else if (!!name) {
      return name;
    } else if (!!acronym) {
      return acronym;
    } else {
      return '';
    }
  }

  private setNewAffiliationAfterCreate(acronym: string, name: string): void {
    const newAffiliation: string = this.buildStringAffiliation(acronym, name);
    this.editForm.controls.affiliation.setValue(newAffiliation);
  }

  public OpenDialogCreateAffiliation(params): void {
    if (params.value) {
      const dialogConfig = new MatDialogConfig();
  
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '30rem';
      dialogConfig.data = {
        affiliations: this.allAffiliations,
      }
  
      const dialogRef = this.dialog.open(CreateAffiliationComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe(
        (data) => {
          if (!!data) {
            this.newAffiliationWasCreated = true;
            this.newAffiliationName = data.name;
            this.newAffiliationAcronym = data.acronym;
            this.setNewAffiliationAfterCreate(this.newAffiliationAcronym, this.newAffiliationName);
          }
          this.blurInputAffiliation();
        }
      );
    }
  }

  private blurInputAffiliation(): void {
    this.inputFilterAutocomplete.input.nativeElement.blur();
    this.inputFilterAutocomplete.inputAutoComplete.closePanel();
  }

  private initAffiliationForm(): String {
    let newAffiliationName: string | null = this.searchUser?.profile?.affiliation.name;
    let acronymAffiliation: string | null = this.searchUser?.profile?.affiliation._acronym;

    newAffiliationName === "undefined" ? newAffiliationName = null : newAffiliationName;
    acronymAffiliation === "undefined" ? acronymAffiliation = null : acronymAffiliation;

    return this.buildStringAffiliation(acronymAffiliation, newAffiliationName);
  }

  unhideForms() {
    this.showForms = true;
    this.initEditForm();
    this.initPasswordForm();
  }

  unselectUser() {
    this.showForms = false;
    this.searchForm.value.users = [];
  }

  submitEdit() {
    const data = _.cloneDeep(this.editForm.value);

    const affiliation = this.allAffiliations.find(aff => aff.filter == this.editForm.value.affiliation);

    data.profile.affiliation = affiliation;
    data.profile.country = data.country
    delete data['country'];
    delete data['affiliation'];
    data.id = this.searchUser.id;

    const dataUser = plainToClass(User, data);

    this.userService.update(dataUser).subscribe(user => {
      this.searchForm.value.users[0] = user;
    }, () => {}, () => {
      this.notifyUpdate();
    });
  }

  submitPassword() {
    const data = this.passForm.value;

    this.userService.updatePassword(this.searchUser.id, data).subscribe(user => {
      this.searchForm.value.users[0].isActive = user.isActive;
      this.initPasswordForm();
    }, () => {}, () => {
      this.notifyUpdate();
    });

  }

  notifyUpdate() {
    this.notificationService.notify('admin.user-search.updated');
  }

  get editFormIsValid(): boolean {
    return this.editForm.valid;
  }

  get passFormIsValid(): boolean {
    return this.passForm.valid;
  }

  get searchUser(): User {
    if (this.searchForm.value.users) {
      return this.searchForm.value.users[0];
    }

    return null;
  }

}
