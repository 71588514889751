import { BaseModel } from './baseModel';
import { Track } from './track';
import { Session } from './session';
import { Type } from 'class-transformer';
import { EventInfo } from './eventInfo';
import { EventType } from './eventType';
import { Form } from './form';
import { EventSubmissionStep } from './eventSubmissionStep';
import { EventTopic } from './eventTopic';
import { UserEventInvite } from './user.event.invite';

export class Event extends BaseModel {
  public anonymity: number;

  @Type(() => Event)
  public eventParent: Event | number;
  @Type(() => Event)
  public subEvents: Array<Event>;
  @Type(() => EventInfo)
  public eventInfo: EventInfo;
  @Type(() => EventType)
  public eventType: EventType;

  @Type(() => UserEventInvite)
  public userEvent: UserEventInvite;

  @Type(() => Form)
  public formReview: Form | number;
  @Type(() => Form)
  public formSubmission: Form | number;
  @Type(() => EventSubmissionStep)
  public eventSubmissionStep: Array<EventSubmissionStep>;

  public maxTopics: string;
  public minTopics: string;

  public subconferences?: Array<any>;

  @Type(() => EventTopic)
  public topics: Array<number | EventTopic>;

  public sessionDuration: string;
  public user: Array<number>;
  @Type(() => Track)
  public tracks: Array<Track>;
  public sessions: Array<Session>;

  public year: number;

  public uri: string;

  public copyrightEnable: boolean;
  public authorRegistrationEnable: boolean;
  public ieeeCopyrightEnable: boolean;
  public ieeePubTitle: string;
  public ieeeArtSource: string;
  public ieeePartNumber: string;
  public isbn: string;
  public issn: string;

  constructor(
    public name: string,
    public acronym: string
  ) {
    super();
  }

  hasTrack(trackID: number): boolean {
    return !!this.tracks.find(t => t.id === trackID);
  }

  get formattedName(): string{
    return `${this.year ? this.acronym + " " +  this.year.toString() : this.acronym} - ${this.name}`
  }
}
