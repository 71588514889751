import {Component, OnInit } from '@angular/core';
import { User } from 'src/app/model/user';
import { UserEventRole } from 'src/app/enum/user.event.role';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UserEvent } from 'src/app/model/user.event';
import { EventsService } from 'src/app/service/events.service';
import { UserService } from 'src/app/service/user.service';
import { NotificationService } from 'src/app/service/notification.service';
import { NO_PAGINATION } from 'src/app/model/pagination.options';
import { AdminService } from 'src/app/service/admin.service';
import { Event } from 'src/app/model/event';
import { EventInfo } from 'src/app/model/eventInfo';
import { UserEventAnswer } from 'src/app/enum/user.event.answer';
import { EMPTY, forkJoin } from 'rxjs';
import { SelectOption } from '../../../../model/select.option';
import { EventTitlePipe } from '../../../../pipe/event.title.pipe';
import { CountryPipe } from '../../../../pipe/country.pipe';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import * as dayjs from 'dayjs';
import * as _ from 'lodash';
import { ExportService } from 'src/app/service/export.service';
import { TranslateService } from '@ngx-translate/core';
import { TPCGroup } from 'src/app/model/tpc.group';
import { NewGroupDialogComponent } from 'src/app/component/new-group-dialog/new-group-dialog.component';
import { TimezoneService } from 'src/app/service/timezone.service';
import { MatFooterRow, MatHeaderRow } from '@angular/material/table';

export enum InheritFromValues {
  tpc_list = 'admin.event.people.tpc.inherit-list.options.tpc',
  invitation_list = 'admin.event.people.tpc.inherit-list.options.invitation'
}

interface Collection {
  id: number;
  name: string;
}
``
@Component({
  selector: 'app-event-members',
  templateUrl: './event-members.component.html',
  styleUrls: ['./event-members.component.scss']
})
export class EventMembersComponent implements OnInit{
  event: Event;
  members: Array<UserEvent>;
  memberTableColumns = ['position', 'name', 'affiliation', 'bid', 'defined-topics', 'group', 'invite-date', 'accepted-date', 'notification-date', 'send-notification'];

  invited: Array<UserEvent> = [];
  invitedTableColumns = ['position', 'name', 'affiliation', 'accept-for', 'reject-for', 'invite-date', 'send-invite'];

  rejected: Array<UserEvent> = [];
  rejectedTableColumns = ['position', 'name', 'affiliation', 'invite-date', 'rejected-date', 'remove'];

  users: Array<User>;
  form: FormGroup;
  formDueAt: FormGroup;

  searchFoundUsers: Array<User>;
  showCreateUserForm = false;
  userSignupForm: FormGroup;

  toggleRejected = false;

  inheritForm: FormGroup;
  inheritChoices = this.toSelectable(InheritFromValues);


  events: Array<SelectOption>;

  tpcGroups: Array<TPCGroup>;
  tpcGroupOptions: Array<SelectOption>
  tpcGroupCollection: Array<Collection> = [];
  formTPCGroups = new FormGroup({});

  cloneFormTPCGroups = new FormGroup({});

  // The use of this list is for disabling and enabling the actions on the table's menu
  selectedList: UserEvent[] = [];
  constructor(
    private adminService: AdminService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private userService: UserService,
    private eventsService: EventsService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    public exportService: ExportService,
    private tzService: TimezoneService,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.getMembers();
          this.getEvents();
          this.initFormDueAt();
          this.initInheritForm();
        });
      } else {
        this.getMembers();
        this.getEvents();
        this.initFormDueAt();
        this.initInheritForm();

      }
    });
  }

  private initInheritForm() : void {
    this.inheritForm = this.fb.group({
      tpcMembersInheritFrom: [this.inheritChoices[0].id, InheritFromValues]
    });
  }

  toIterable(object): Array<string> {
    return Object.keys(object).filter(key => typeof object[key] !== 'function');
  }

  toSelectable(object): Array<{ id: number, value: string }> {
    return this.toIterable(object).map((key, index) => ({
      id: index,
      value: object[key].toString()
    }));
  }

  public getMembers(): void {
      this.adminService.eventsService.getUserEventListByRole(this.event.id, UserEventRole.COMMITTEE).subscribe(members => {
        this.setUsers(members.filter(m => m.invitationAnswer.toLowerCase() === UserEventAnswer.ACCEPTED), 'member');
        this.setUsers(members.filter(m => m.invitationAnswer.toLowerCase() === UserEventAnswer.NOT_ANSWERED), 'invited');
        this.setUsers(members.filter(m => m.invitationAnswer.toLowerCase() === UserEventAnswer.REJECTED), 'rejected');
        this.getEventTPCGroups();
      }, (error) => {
        this.setUsers([], 'member');
        this.setUsers([], 'invited');
        this.setUsers([], 'rejected');

        this.getEventTPCGroups();
        return EMPTY;
      });

  }

  private getEventTPCGroups() {
    this.eventsService.getEventTPCGroups(this.event.id).subscribe(groups => {
      this.tpcGroups = groups;
      this.tpcGroupOptions = this.tpcGroups.map(tpcGroup => new SelectOption(tpcGroup.id, tpcGroup.name));

      // members have incomplete UserEvent
      for(let i = 0; i < this.members.length; i++){
        const member: UserEvent = this.members[i];

        const name = `tpcGroup-${member.id}`;
        const groupId: (number | null) = member.tpcGroup ? member.tpcGroup : null;
        this.formTPCGroups.addControl(name, new FormControl(groupId));
        this.tpcGroupCollection.push({id: member.id, name});
      }

      this.initForm();
    }, (error) => {
      this.initForm();
    }, () => {
      this.generateCommitteeExport();
    });

  }

  updatePaperGroupList(options:Array<SelectOption>) {
    this.tpcGroupOptions = options;
  }

  private getEvents(): void {
    const paginationOptions = NO_PAGINATION;
    paginationOptions.show = "all";
    this.adminService.eventsService.getAllEventsWithMinimumDetails(paginationOptions).subscribe(events => {
      this.events = events
        .filter(event => (event['id'] !== this.event.id))
        .map(event =>
          new SelectOption(event, EventTitlePipe.prototype.transform(event))
        );
    });
  }

  public initForm(): void {
    this.form = this.fb.group({
      members: [this.members.map(member => member.user)],
      invited: [],
      inheritEvent: []
    });

    this.form.get('members').valueChanges.subscribe((users: Array<User>) => {
      users.forEach(user => {
        if (!this.members.find(u => u.id === user.id)) {
          this.setUserAsMember(this.event, user);

          // Remove usuário da lista de pesquisa de usuários.
          return users.splice(0, 1);
        }
      });
    });

    this.form.get('invited').valueChanges.subscribe((users: Array<User>) => {
      users.forEach(user => {
        if (!this.invited.find(u => u.id === user.id)) {
          this.inviteUserAsMember(this.event, user);

          // Remove usuário da lista de pesquisa de usuários.
          return users.splice(0, 1);
        }
      });
    });
    this.generateCommitteeExport();
  }

  private initFormDueAt(): void {
    this.formDueAt = this.fb.group({
      tpcInvitationDueAt: [this.event.eventInfo.tpcInvitationDueAt ?.toDate()],
      timeZone: [this.tzService.browserTimeZone]
    });
  }

  public removeUser(id: number, type: 'member' | 'invited' | 'rejected'): void {
    this.adminService.progressBar.start();
    this.eventsService.deleteUserEvent(id).subscribe(() => {
      switch (type) {
        case 'member':
          this.setUsers(this.members.filter(member => member.id !== id), type);
          this.notificationService.notify('admin.event.people.tpc.removed-user-as-committee');
          break;
        case 'invited':
          this.setUsers(this.invited.filter(member => member.id !== id), type);
          this.notificationService.notify('admin.event.people.tpc.removed-user-invited');
          break;
        case 'rejected':
          this.notificationService.notify('admin.event.people.tpc.removed-user-as-committee');
          this.setUsers(this.rejected.filter(member => member.id !== id), type);
          break;
      }
      this.adminService.progressBar.stop();
    });
  }


  public removeSelectedRejected(): void {
    let rejecteds = this.rejected.filter(rejected => rejected.sendInvite).map(rejected => rejected.id);

    rejecteds.forEach(rejected => {
      this.removeUser(rejected, "rejected");
    })
  }

  public removeSelectedMembers(): void {
    this.adminService.progressBar.start();  
  
    let members_id = this.members.filter(member => member.sendInvite).map(member => member.id); 
  
    if (members_id.length === 0) {
      this.adminService.progressBar.stop(); 
      return;
    }

    if(members_id.length == this.members.length){
      this.removeAllMembers();
      this.adminService.progressBar.stop(); 
      return;
    }

    forkJoin(
      members_id.map((id: number) => this.eventsService.deleteUserEvent(id))  
    ).subscribe({
      next: () => {
        this.setUsers(this.members.filter(member => !members_id.includes(member.id)), 'member');
      },
      complete: () => {
        this.adminService.progressBar.stop();
  
        if (members_id.length > 1) {
          this.notificationService.notify('admin.event.people.tpc.removed-users-as-committee');
        } else {
          this.notificationService.notify('admin.event.people.tpc.removed-user-as-committee');
        }
      },
      error: () => {
        this.adminService.progressBar.stop(); 
        this.notificationService.notify('admin.event.people.tpc.error-removing-user'); 
      }
    });
  }

  public removeSelectedInvited(){    
    const selected = this.invited.filter(invited => invited.sendInvite).map(rejected => rejected.id);

    selected.forEach(id => {
      this.removeUser(id, "invited");
    })
  }

  private resetCheckButtonMembers(): void {
    this.members.forEach(member => member.sendInvite = false);
  }

  private resetCheckButtonInvited(): void {
    this.invited.forEach(invited => invited.sendInvite = false);
  }
  
  private setUsers(users: Array<UserEvent>, type: 'member' | 'invited' | 'rejected'): void {
    switch (type) {
      case 'member':
        this.members = users;
        this.generateCommitteeExport();
        break;
      case 'invited':
        this.invited = users;
        break;
      case 'rejected':
        this.rejected = users;
        break;
    }

    this.updateUsers();
  }

  private pushUser(user: UserEvent, type: 'member' | 'invited' | 'rejected'): void {

    if (user.claims < 1 || user.claims == undefined) {
      user.claims = 0;
    }

    switch (type) {
      case 'member':
        this.members = this.members.concat([user]);
        this.generateCommitteeExport();
        break;
      case 'invited':
        this.invited = this.invited.concat([user]);
        break;
      case 'rejected':
        this.rejected = this.rejected.concat([user]);
        break;
    }

    this.updateUsers();
  }

  private updateUsers(): void {
    this.users = this.members.map(c => c.user)
      .concat(...this.invited.map(c => c.user))
      .concat(...this.rejected.map(c => c.user));
  }

  updatedSearchUser(event: { found: boolean, value: boolean }): void {
    const { found, value } = event;

    this.showCreateUserForm = !found && value;
  }

  setUserAsMember(event: Event, user: User): void {
    this.adminService.progressBar.start();
    this.notificationService.notify('admin.event.people.tpc.setting-user-as-committee', { params: { user: user.name } });
    this.adminService.eventsService.setUserEvent(event.id, user.id, UserEventRole.COMMITTEE, UserEventAnswer.ACCEPTED).subscribe(
      newMember => {
        const name = `tpcGroup-${newMember.id}`;
        this.formTPCGroups.addControl(name, new FormControl(null));
        this.tpcGroupCollection.push({id: newMember.id, name});
        this.cloneFormTPCGroups = _.cloneDeep(this.formTPCGroups);

        this.adminService.progressBar.stop();
        this.pushUser(newMember, 'member');
        this.notificationService.notify('admin.event.people.tpc.set-user-as-committee', { params: { user: user.name } });
      },
      error => {
        this.adminService.progressBar.stop();
      }
    );
  }

  inviteUserAsMember(event: Event, user: User): void {
    this.adminService.progressBar.start();
    this.notificationService.notify('admin.event.people.tpc.inviting-user-as-committee', { params: { user: user.name } });

    this.adminService.eventsService.setUserEvent(event.id, user.id, UserEventRole.COMMITTEE, UserEventAnswer.NOT_ANSWERED).subscribe(
      newMember => {
        this.adminService.progressBar.stop();
        this.pushUser(newMember, 'invited');
        this.notificationService.notify('admin.event.people.tpc.set-user-as-invited-to-committee', { params: { user: user.name } });
      },
      error => {
        this.adminService.progressBar.stop();
      }
    );
  }

  resendActivationEmail(user: User): void {
    this.notificationService.notify('admin.event.people.tpc.activation-email.sending', { params: { user: user.name } });
    this.userService.resendActivationEmail(user.email).subscribe(
      response => {
        this.notificationService.notify('admin.event.people.tpc.activation-email.sent', { params: { user: user.name } });
        this.adminService.progressBar.stop();
      },
      error => {
        if (error.status === 304) {
          this.notificationService.notify('errors.user-already-active');
        }
        this.adminService.progressBar.stop();
      }
    );
  }

  sendMembersNotifications(): void {
    const usersNotificated = this.members.filter(e => e.sendInvite);
    const usersID = usersNotificated.map(e => e.user.id);

    if (usersID && usersID.length > 0) {
      this.adminService.progressBar.start();
      this.notificationService.notify('admin.event.people.tpc.sending-notification-added-tpc-member');

      this.eventsService.sendTpcMemberNotificationEmail(this.event.id, usersID).subscribe(() => {
        usersNotificated.forEach(user => {
          user.notificationSentAt = dayjs();
        });

        this.adminService.progressBar.stop();
        this.notificationService.notify('admin.event.people.tpc.notification-sent');
      });
    }

    this.resetCheckButtonMembers();
  }

  sendInvites(): void {
    const users = this.invited.filter(e => e.sendInvite).map(e => e.user.id);
    if (users && users.length > 0) {
      this.adminService.progressBar.start();
      this.notificationService.notify('admin.event.people.tpc.sending-invite');

      this.eventsService.sendInvitationEmail(this.event.id, users).subscribe(() => {
        this.invited.filter(e => e.sendInvite).forEach(user => {
          user.invitationSentAt = dayjs();
        });

        this.adminService.progressBar.stop();
        this.notificationService.notify('admin.event.people.tpc.invite-sent');
      });
    }
    this.resetCheckButtonInvited();
  }

  private selectAllMembers() {
    this.members.forEach(user => user.sendInvite = true);
    // Update the list of selected users
    this.setSelectedList('members');
  }

  private deselectAllMembers() {
    this.members.forEach(user => user.sendInvite = false);
    // Update the list of selected users
    this.setSelectedList('members');
  }

  private selectAllInvited() {
    this.invited.forEach(user => user.sendInvite = true);
    // Update the list of selected users
    this.setSelectedList('invited');
  }

  private deselectAllInvited() {
    this.invited.forEach(user => user.sendInvite = false);
    // Update the list of selected users
    this.setSelectedList('invited');
  }

  private selectAllDeclined() {
    this.rejected.forEach(user => user.sendInvite = true);
    // Update the list of selected users
    this.setSelectedList('rejected');
  }

  private deselectAllDeclined() {
    this.rejected.forEach(user => user.sendInvite = false);
    // Update the list of selected users
    this.setSelectedList('rejected');
  }

  inheritTPC(inheritTo : 'tpc_list' | 'invitation_list') {
    const fromEvent = this.form? this.form.value.inheritEvent : null;
    const choiceIndex = this.inheritForm.value.tpcMembersInheritFrom;

    if (fromEvent) {
      const path = {
        from: this.toIterable(InheritFromValues)[choiceIndex] == 'tpc_list' ? 'tpc' : 'invitation',
        to: inheritTo == 'tpc_list' ? 'tpc' : 'invitation'
      }
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: `admin.event.people.tpc.inherit-list.confirm-dialog.title.${path.from}-to-${path.to}`,
          content: ''
        }
      });

      dialogRef.afterClosed().subscribe(confirmed => {
        if (confirmed) {
          const inheritFrom = path.from == 'tpc' ? 'tpc_list' : 'invitation_list';
          const params = {who_to_inherit : inheritFrom, where_to_inherit: inheritTo};
          this.eventsService.inheritTPCList(<number>fromEvent.id, this.event.id, params).subscribe(inheritedUsedEvents => {
            const users = inheritedUsedEvents.length;

            this.notificationService.notify(`admin.event.people.tpc.inherit-list.action-completed.${path.to}`,
              { params: { users } }
            );

            this.ngOnInit();
          });
        }
      });
    }
  }

  answerInviteAsChair(userEvent: UserEvent, answer: UserEventAnswer) {
    this.eventsService.putUserEvent(userEvent.id, this.event.id, userEvent.user.id, UserEventRole.COMMITTEE, answer)
      .subscribe(e => {
        if (e.invitationAnswer.toUpperCase() === UserEventAnswer.ACCEPTED.toUpperCase()) {
          this.notificationService.notify('admin.event.people.tpc.set-user-as-committee', { params: { user: e.user.name } });
        } else if (e.invitationAnswer.toUpperCase() === UserEventAnswer.REJECTED.toUpperCase()) {
          this.notificationService.notify('admin.event.people.tpc.removed-user-as-committee');
        }

        // Update TPC Lists
        this.getMembers();
      });
  }

  public removeAllMembers(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'admin.event.people.tpc.remove-all-members.confirm.title',
        content: 'admin.event.people.tpc.remove-all-members.confirm.content'
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.adminService.progressBar.start();

        forkJoin(this.members.map(({ id }) => this.eventsService.deleteUserEvent(id))).subscribe(res => {
          this.setUsers([], 'member');
          this.getMembers();

          this.notificationService.notify('admin.event.people.tpc.remove-all-members.success');
          this.adminService.progressBar.stop();
        }, error => {
          console.error(error);
          this.notificationService.notify('admin.event.people.tpc.remove-all-members.error');
          this.adminService.progressBar.stop();
        });
      }
    });
  }

  public removeAllInvited(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'admin.event.people.tpc.remove-all-invited.confirm.title',
        content: 'admin.event.people.tpc.remove-all-invited.confirm.content'
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.adminService.progressBar.start();

        forkJoin(this.invited.map(({ id }) => this.eventsService.deleteUserEvent(id))).subscribe(res => {
          this.setUsers([], 'invited');
          this.getMembers();

          this.notificationService.notify('admin.event.people.tpc.remove-all-invited.success');
          this.adminService.progressBar.stop();
        }, error => {
          console.error(error);
          this.notificationService.notify('admin.event.people.tpc.remove-all-invited.error');
          this.adminService.progressBar.stop();
        });
      }
    });
  }

  public answerInviteBatch(answer: UserEventAnswer): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: `admin.event.people.tpc.${answer}-invited.confirm.title`,
        content: `admin.event.people.tpc.${answer}-invited.confirm.content`
      }
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.adminService.progressBar.start();
        const selected = this.invited.filter(invited => invited.sendInvite);
        
        forkJoin(selected.map(userEvent => this.eventsService.putUserEvent(
          userEvent.id, this.event.id, userEvent.user.id, UserEventRole.COMMITTEE, answer
        ))).subscribe({
          complete: () => {
          this.setUsers([], 'invited');
          this.getMembers();
          this.resetCheckButtonInvited();
          this.notificationService.notify(`admin.event.people.tpc.${answer}-invited.success`);
          this.adminService.progressBar.stop();
        }, 
        error: (error) => {
          console.error(error);
          this.notificationService.notify(`admin.event.people.tpc.${answer}-invited.error`);
          this.adminService.progressBar.stop();
        }})
      }
    });
  }

  public generateCommitteeExport() {
    const countryPipe = new CountryPipe(this.translateService);
    if (this.members && this.members.length > 0){
      setTimeout(() => {
        const membersEmail = this.members.map(m => `\"${m.user.name}\" <${m.user.email}>`).join('\n');
        const membersCSV = this.members.map(m => `${m.user.name};${m.user.profile.affiliation.name};${countryPipe.transform(m.user.profile.country)};${m.user.email}`).join('\n');

        this.exportService.setDownloadTarget('exportMembers', `${this.event.name}-committee.txt`, membersEmail, 'text/plain');
        this.exportService.setDownloadTarget('exportMembersCSV', `${this.event.name}-committee.csv`, membersCSV, 'text/plain');
      });
    }
  }

  public exportMembersToText(members: Array<UserEvent>, filenameSuffix: string) {
    const membersEmail = members.map(m => `\"${m.user.name}\" <${m.user.email}>`).join('\n');
    this.exportService.downloadFile(`${this.event.name}-${filenameSuffix}.txt`, membersEmail, 'text/plain');
  }
  
  public exportMembersToCSV(members: Array<UserEvent>, filenameSuffix: string) {
    const countryPipe = new CountryPipe(this.translateService);
    const membersCSV = members.map(m => `${m.user.name};${m.user.profile.affiliation.name};${countryPipe.transform(m.user.profile.country)};${m.user.email}`).join('\n');
    this.exportService.downloadFile(`${this.event.name}-${filenameSuffix}.csv`, membersCSV, 'text/csv');
  }

  public exportMembers() {
    this.exportMembersToText(this.members, 'committee');
  }

  public exportMembersCSV() {
    this.exportMembersToCSV(this.members, 'committee');
  }

  public exportInvitedMembers() {
    this.exportMembersToText(this.invited, 'invited');
  }

  public exportInvitedMembersCSV() {
    this.exportMembersToCSV(this.invited, 'invited');
  }

  public exportRejectedMembers() {
    this.exportMembersToText(this.rejected, 'rejected');
  }

  public exportRejectedMembersCSV() {
    this.exportMembersToCSV(this.rejected, 'rejected');
  }

  public changeTab(tab) {
    const TPC_MEMBERS = 1;

    if (tab === TPC_MEMBERS) {
      this.generateCommitteeExport();
    }
  }

  getLengthTopicsList() {
    return this.event.topics.length.toString().length + '.0-0';
  }

  getMemberTopicsInterestLength(member: UserEvent) : number {
    let topicsInterestLength: number;
    if (member.topicsInterest.length >= 1) {
      topicsInterestLength = member.topicsInterest.length;
    } else {
      topicsInterestLength = Number(member.topicsInterest);
    }

    return topicsInterestLength;
  }

  public setDefaultDeadline() {
    if (this.formDueAt.valid) {
      let eventInfo;
      this.eventsService.getEventInfo(this.event.id).subscribe(eventInfoData => {
        eventInfo = eventInfoData as EventInfo;
        eventInfo.tpcInvitationDueAt = this.formDueAt.value.tpcInvitationDueAt;

        this.notificationService.notify('admin.event.settings.edit-event-info-start', {
          params: {
            id: this.event.id
          }
        });
        this.eventsService.editEventInfo(this.event.eventInfo.id, eventInfo).subscribe(event => {
          this.event.eventInfo = event;
          this.adminService.selectedEvent = this.event;
          this.notificationService.notify('admin.event.settings.edit-event-info-success', {
            params: {
              id: this.event.id
            }
          });
        });
      });
    } else {
      this.formDueAt.markAllAsTouched();
      this.notificationService.notify('admin.event.settings.edit-event-info-error', {
        params: {
          id: this.event.id
        }
      });
    }
  }
  private setSelectedList(table: string): void{
    switch(table){
      case 'members':
        this.selectedList = this.members.filter(e => e.sendInvite);
        break;
      case 'invited':
        this.selectedList = this.invited.filter(e => e.sendInvite);
        break;
      case 'rejected':
        this.selectedList = this.rejected.filter(e => e.sendInvite);
        break;
    }
  }

  public get atLeastOneSelection() : Boolean {
    return this.selectedList.length > 0;
  }

  public disabledTooltip(){
    if (!this.atLeastOneSelection) {
      return this.translateService.instant('forms.buttons.validation.selectPeople');
    }
    return "";
  }
}
