import { File } from './file';
import { Track } from './track';
import { EventTopic } from './eventTopic';
import { User } from './user';
import { Author } from './author';
import { Type, plainToClass } from 'class-transformer';
import { SubmissionStatus } from '../enum/submission.status';
import { Form } from './form';
import { Answer } from './answer';
import { Event } from './event';
import { Session } from './session';
import { BaseModel, toDayjs } from './baseModel';
import { PaperGroup } from './paper.group';
import { TPCGroup } from './tpc.group';
import { PrintingProblem } from './printing.problem';
import { Transform } from 'class-transformer';
import * as dayjs from 'dayjs';
import { FormFieldType } from '../enum/form.field.type';
import { ReviewStatus } from '../enum/review.status';
import { PaperReviewHistory } from './paper-review-history';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { FileRules } from './file.rules';
import { Rebuttal } from './rebuttal';
import { DiscussionMessage } from './discussionMessage';
import { SubmissionHistory } from './submission-history';
import { UserReview } from './user.review';

export class Submission extends BaseModel {
  constructor() { super(); }

  get trackID(): number {
    return (<Track>this.track).id ? (<Track>this.track).id : (<number>this.track);
  }

  get trackName(): string {
    return (<Track>this.track).id ? (<Track>this.track).name : (<number>this.track).toString();
  }

  @Type(() => Author)
  authors: Array<Author>;

  @Type(() => Form)
  formSubmission: Form;

  @Type(() => Form)
  form: Form;

  @Type(() => File)
  files: Array<File>;

  @Type(() => Answer)
  formAnswer: Array<Answer>;

  @Type(() => PaperGroup)
  paperGroup: PaperGroup | number;

  tpcGroup: any;

  @Type(() => User)
  userClaims: Array<User>;

  @Type(() => User)
  usersByInterest: Array<User>;

  @Type(() => Session)
  session: Session | number;

  visible = true;
  favorite = false;

  // has reviews
  hr: boolean;
  flagReviewer: boolean;

  contributions = '';

  //Publication status
  authorRegistration: boolean;
  copyrightIEEE: boolean;
  checkListCustom:  Array<{
    fieldId: number,
    status: boolean
  }>;

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  @Type(() => Review)
  reviews: Array<Review | number>;
  rebuttal: string;

  @Type(() => DiscussionMessage)
  discussionMessages: Array<DiscussionMessage> = [];

  status: SubmissionStatus;
  title: string;
  abstract: string;

  @Type(() => EventTopic)
  topics: Array<EventTopic> = [];

  @Type(() => Track)
  track: Track | number;

  @Type(() => Event)
  event: Event;

  @Transform(toDayjs)
  statusChangedAt: dayjs.Dayjs;

  @Transform(toDayjs)
  submittedAt: dayjs.Dayjs;

  data(): object {
    return {
      status: this.status.toUpperCase(),
      title: this.title,
      abstract: this.abstract,
      track: this.trackID,
      authors: this.authors.map(author => ({ order: author.order, user: author.user.id })),
      topics: this.topics.map(e => e.id),
      contributions: this.contributions,
      formAnswer: this.formAnswer
    };
  }

  updateData(data: {
    status: SubmissionStatus, title: string, abstract: string,
    topics: EventTopic[], track: Track, authors: { user: User, order: number }[],
    formAnswers: Array<Answer>
  }): void {
    this.status = data.status;
    this.title = data.title;
    this.abstract = data.abstract;
    this.track = data.track;
    this.authors = data.authors;
    this.topics = data.topics;
    this.formAnswer = data.formAnswers;
  }

  get reviewBlocked(): boolean {
    switch (this.status) {
      case SubmissionStatus.ACCEPTED:
      case SubmissionStatus.REJECTED:
      case SubmissionStatus.WITHDRAWN:
      // case SubmissionStatus.PUBLISHED:
        return true;
      default:
        return false;
    }
  }

  public get editable(): boolean {
    return !(<Track>this.track).closed || 
    ((<Track>this.track).editPendingSubmissions && this.status === SubmissionStatus.PENDING) ||
    ((<Track>this.track).editActiveSubmissions && this.status === SubmissionStatus.ACTIVE) ||
    ((<Track>this.track).editRejectedSubmissions && this.status === SubmissionStatus.REJECTED) ||
    ((<Track>this.track).editAcceptedSubmissions && this.status === SubmissionStatus.ACCEPTED);
  }

  public get withdrawable(): boolean {
    // If it is under revision, checks if it's enabled to withdraw papers in this stage
    if (this.hr && (this.status === SubmissionStatus.PENDING || this.status === SubmissionStatus.ACTIVE)){
      if(!(<Track>this.track).withdrawUnderReviewSubmissions) return false
      // else, just check if its enabled for its status
    }
    return !(<Track>this.track).closed || 
    ((<Track>this.track).withdrawPendingSubmissions && this.status === SubmissionStatus.PENDING) ||
    ((<Track>this.track).withdrawActiveSubmissions && this.status === SubmissionStatus.ACTIVE) ||
    ((<Track>this.track).withdrawRejectedSubmissions && this.status === SubmissionStatus.REJECTED) ||
    ((<Track>this.track).withdrawAcceptedSubmissions && this.status === SubmissionStatus.ACCEPTED);
  }

  public get deletedTrack(): boolean {
    if(this.track){
      return (<Track>this.track).isDeleted
    }else return false
  }

  get mainAuthor(): User {
    return this.authors[0].user;
  }

  isAuthor(id: number): boolean {
    return !!this.authors.find(e => e.user.id === id);
  }

  hasClaim(id: number): boolean {
    return this.userClaims.some(u => (typeof u === 'number' ? u : u.id) == id);
  }

  isReviewer(userId: number): boolean {
    return this.reviews.some(review => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (review instanceof Review) {
        return review.userId === userId && review.status !== ReviewStatus.DELETED;
      }
      return false;
    });
  }

  isSessionChair(userId: number): boolean {
    if (this.session) {
      if (typeof(this.session, Session)) {
        if ((<Session>this.session).chair) {
          let u = (<Session>this.session).chair;
          if ((typeof u === 'number' ? u : u.id) == userId){
            return true;
          }
        }
      }
    }
    return false;
  }

  get authorNames(): string {
    return this.authors.map(a => a.user.name).join(', ');
  }

  get authorEmails(): string {
    return this.authors.map(a => a.user.email).join(', ');
  }

  findReviewIndex(reviewId: number): number {
    return this.reviews.findIndex(r => {
      if (r instanceof Review) {
        return r.id === reviewId;
      }
      return r === reviewId;
    });
  }

  getSubmissionFormAnswers(form?: Form): Array<{
    order: number, shortLabel: string, description: string,
    answers: Array<string>
  }> {
    if (form) {
      this.formSubmission = form;
    }
    return this.formSubmission.fields.map(field => {
      const hasFormAnswer = !!this.formAnswer;

      let fieldAnswers;
      if (hasFormAnswer) {
        fieldAnswers = this.formAnswer.filter(a => a.formField === field.id);
      } else {
        fieldAnswers = field.answers;
      }

      return {
        order: field.order,
        shortLabel: field.shortLabel,
        description: field.description,
        answers: fieldAnswers.map(answer => {
          // Is assignable field
          if (answer.fieldChoice) {
            const choice = field.choices.find(c => answer.fieldChoice === c.id);
            // Deletes unselected options
            if (field.type == FormFieldType.CHECKBOX && answer.value != "true") {
              return null;
            }
            return `${choice.description}`;
          }
          // Is text field
          return `${answer.value}`;
        })
      };
    });
  }

  getOpenTrackFiles(): Array<FileRules> {
    return this.track instanceof Track ? this.track.trackFiles.filter(t => t.open) : undefined;
  }
}

export { SubmissionStatus as SubmissionStatus };

export class DeletedSubmission extends Submission {
  @Type(() => Event)
  public event: Event;

  @Type(() => User)
  public deletedBy: User;

  @Transform(toDayjs)
  public deletedAt: dayjs.Dayjs;
  
  public previousStatus: SubmissionStatus;
}

export class Review extends BaseModel {
  @Type(() => Submission)
  submission: Submission;

  @Type(() => Form)
  form: Form;

  @Type(() => Answer)
  answers: Array<Answer> = [];

  @Type(() => User)
  assignedBy?: User | number;

  @Type(() => Boolean)
  isFirstAssign: boolean;

  @Type(() => User)
  user?: User | number;

  @Transform(toDayjs)
  public dueAt: dayjs.Dayjs;

  public visible = true;
  public favorite = false;
  public underDraft = false;

  public eventId: number;

  @Transform(toDayjs)
  public assignedAt: dayjs.Dayjs;

  @Transform(toDayjs)
  public remindedAt: dayjs.Dayjs;

  @Transform(toDayjs)
  public confirmedAt: dayjs.Dayjs;

  @Transform(toDayjs)
  public completedAt: dayjs.Dayjs;

  @Type(() => PrintingProblem)
  printingProblems: Array<PrintingProblem>;

  isReviewerDistributor: boolean;

  reviewQuestionVisibility = this.wasAnswered();

  constructor(
    public status: ReviewStatus
  ) { super(); }

  public wasAnswered(): boolean {
    return this.status === ReviewStatus.COMPLETED;
  }

  public get assignedByUserName(): string {
    return this.assignedBy instanceof User ? this.assignedBy.name : undefined;
  }

  public get assignedByUserId(): number {
    return this.assignedBy instanceof User ? this.assignedBy.id : this.assignedBy;
  }

  public get userName(): string {
    return this.user instanceof User ? this.user.name : undefined;
  }

  public get userId(): number {
    return this.user instanceof User ? this.user.id : this.user;
  }

  isReviewBlocked(globalBlockedReview: boolean, userIsChair: boolean, userIsAuthor: boolean): boolean {
    if ((userIsChair && !userIsAuthor && !(this.status === ReviewStatus.DECLINED))) {
      return false;
    } else if (userIsChair && userIsAuthor) {
      return true;
    } else if (!userIsAuthor &&
      this.status === ReviewStatus.ASSIGNED ||
      this.status === ReviewStatus.DELEGATED ||
      this.status === ReviewStatus.NOTIFIED ||
      this.status === ReviewStatus.DECLINED ||
      this.status === ReviewStatus.DELETED ||
      this.submission.status === SubmissionStatus.ACCEPTED ||
      this.submission.status === SubmissionStatus.REJECTED ||
      this.submission.status === SubmissionStatus.WITHDRAWN ||
      (globalBlockedReview && (dayjs() > this.dueAt))
    ) {
      return true;
    }
    return false;
  }

  public get nonEditable(): boolean {
    return this.status === ReviewStatus.ASSIGNED || this.status === ReviewStatus.NOTIFIED;
  }
}
