import { Component, forwardRef, Input, Output, EventEmitter, OnInit, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { TextInputComponent } from '../text-input/text-input.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '../../model/select.option';
import { MatSelectChange } from '@angular/material/select';
import { MatSelect } from '@angular/material/select';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { EventsService } from 'src/app/service/events.service';
import { TranslateService } from '@ngx-translate/core';
import { NewGroupDialogComponent } from '../new-group-dialog/new-group-dialog.component';
import { NotificationService } from 'src/app/service/notification.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { NavbarService } from 'src/app/service/navbar.service';
import { Submission } from 'src/app/model/paper';
import { TPCGroup } from 'src/app/model/tpc.group';
import { UserEvent } from 'src/app/model/user.event';

@Component({
  selector: 'app-select-input-group',
  templateUrl: './select-input-group.component.html',
  styleUrls: ['./select-input-group.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectInputGroupComponent),
    multi: true
  }]
})
export class SelectInputGroupComponent extends TextInputComponent implements OnInit, OnChanges, AfterViewInit {
  constructor(
    private dialog:MatDialog,
    private eventsService: EventsService,
    private translate: TranslateService,
    private navbarService: NavbarService,
    private submissionsService: SubmissionsService,
    private notificationService: NotificationService,
    ) {
    super();
  }
  @ViewChild(MatSelect) matSelect: MatSelect;

  @Input() type: string;
  @Input() options;
  @Input() indices = {
    internal: 'id',
    public: 'value'
  };
  @Input() submission: Submission;
  @Input() member: UserEvent;
  @Input() tpcGroups: Array<TPCGroup> = [];
  @Input() event: number;
  @Output() optionListChanges: EventEmitter<Array<SelectOption>> = new EventEmitter<Array<SelectOption>>();
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() Update: EventEmitter<Submission> = new EventEmitter<Submission>();

  @Input() prependPublic = '';

  @Input() compact = false;
  @Input() includeNoneOption = false;

  @Input() filterable = false;
  @Input() indicesFilter: string = 'public';
  filteredOptions: Array<SelectOption>;

  @Input() LastOptionBold = false;
  @Input() readonly disabledDeleteOption = false;
  @Input() readonly disabledNewOption = false;

  @Input() fontSet: string;
  @Input() fontIcon: string;

  private initialValue: string | null;
  public value: string;

  ngAfterViewInit(): void {
    this.matSelect.openedChange.subscribe((opened) => {
      if (opened) {
        const maskOverlay: HTMLElement = document.querySelector('[id^="cdk-overlay"]');
        maskOverlay.style.cursor = 'pointer';
        maskOverlay.addEventListener('click', () => this.matSelect.close());
      }
    });
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  ngOnInit() {
    if (this.filterable && this.options) {
      // Create a new, mutable, copy of the options variable.
      this.filteredOptions = this.options.slice();
    } else {
      // Both variables are the same. A new array is not created.
      this.filteredOptions = this.options;
    }
    if (!this.filteredOptions.find(group => group.id === -1) ) {
      this.addNewGroupOption();
    }
    this.setInitialValue();
  }

  private setInitialValue(): void {
    this.initialValue = this.formGroupController.get(this.name)?.value;
    this.value = this.getDisplayValue(this.initialValue);
  }

  public paperGroupChange($group: MatSelectChange): void {
    switch (this.type) {
      case 'paperGroup':
        this.submitPaperGroupChange($group.value);
        this.submission.paperGroup = $group.value;
        this.Update.emit(this.submission);
        break;
      case 'TPCGroupSubmission':
        this.submitTPCGroupSubmission($group.value);
        break;
      case 'TPCGroupMember':
        this.submitTPCGroupMember($group.value);
        break;
    }
  }

  private submitPaperGroupChange(paperGroupID: number): void {
    if (paperGroupID === -1) {
      this.openNewPaperGroupDialog();
      return;
    }

    this.navbarService.loadingStart();

    const submissionInfo = {
      title: this.submission.title,
      status: this.submission.status,
      paperGroup: paperGroupID,
    }

    this.submissionsService.editMinimumSubmission(submissionInfo, this.submission.id)
      .subscribe(submission => {
        this.value = this.getDisplayValue(submissionInfo.paperGroup);
        this.editPaperGroupNotification(submission, submissionInfo.paperGroup)
      });

    this.navbarService.loadingStop();
  }

  private submitTPCGroupSubmission(tpcGroupSubmissionID: number): void {
    if (tpcGroupSubmissionID === -1) {
      this.openNewTPCGroupSubmissionDialog();
      return;
    }

    this.navbarService.loadingStart();

    const submissionInfo = {
      title: this.submission.title,
      status: this.submission.status,
      tpcGroup: tpcGroupSubmissionID,
    };

    this.submissionsService.editMinimumSubmission(submissionInfo, this.submission.id)
      .subscribe(submission => {
        this.value = this.getDisplayValue(submissionInfo.tpcGroup);
        this.editTPCGroupNotification(submission, submissionInfo.tpcGroup);
      });

    this.navbarService.loadingStop();
  }

  private submitTPCGroupMember(tpcGroupMemberID: number): void {
    if (tpcGroupMemberID === -1) {
      this.openNewTPCGroupSubmissionDialog();
      return;
    }

    this.member.tpcGroup = tpcGroupMemberID;

    this.eventsService.editEventPeople(this.member, this.event).subscribe(member => {
      this.value = this.getDisplayValue(this.member.tpcGroup);
      this.editTPCGroupMemberNotification(this.member.tpcGroup);
    });
  }

  private editPaperGroupNotification(submission: Submission, paperGroupID: number) {
    this.notificationService.notify('submissions.edit.group', {
      params: {
        id: submission.id,
        group: this.filteredOptions.find(g => g.id === paperGroupID).value
      }
    });
  }

  private editTPCGroupNotification(submission: Submission, TPCGroupID: number) {
    this.notificationService.notify('submissions.edit.tpc-group', {
      params: {
        id: submission.id,
        TPCGroup: this.filteredOptions.find(g => g.id === TPCGroupID).value
      }
    });
  }

  private editTPCGroupMemberNotification(TPCGroupID: number) {
    this.notificationService.notify('admin.event.people.member-groups.tpc-group-changed', {
      params: {
        name: `${this.member.user.firstName} ${this.member.user.lastName}`,
        group: this.filteredOptions.find(g => g.id === TPCGroupID).value
      }
    });
  }

  private deleteGroupNotification(title: string, groupDeleted: string) {
    this.notificationService.notify(title, {
      params: {
        group: groupDeleted
      }
    });
  }

  public openNewPaperGroupDialog(): void {
    const dialogConfig = this.buildDialogConfig('admin.event.groups.labels.add-new');

    const dialogRef = this.dialog.open(NewGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => this.addGroup(data));
  }

  private openNewTPCGroupSubmissionDialog(): void {
    const dialogConfig = this.buildDialogConfig('admin.event.people.member-groups.new-group.title');

    const dialogRef = this.dialog.open(NewGroupDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(data => this.addTPCGroupSubmission(data));
  }

  private buildDialogConfig(title?: string, params?: {}, content?): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title,
      params,
      content
    };

    return dialogConfig;
  }

  private addGroup(data: {group: string}): void {
    if (!!data) {
      this.eventsService.addEventPaperGroup(this.event, data.group).subscribe(group => {
        this.addOption(group.name, group.id)
        this.formGroupController.controls[this.name].setValue(group.id);
        this.value = this.getDisplayValue(group.id);
        this.reorderGroups();
        this.submitPaperGroupChange(group.id);
        this.optionListChanges.emit(this.filteredOptions);
      });
      return;
    }
    this.resetForm();
  }

  private addTPCGroupSubmission(data: { group: string }): void {
    if (!!data) {
      this.eventsService.addEventTPCGroup(this.event, data.group).subscribe(group => {
        this.addOption(group.name, group.id)
        this.formGroupController.controls[this.name].setValue(group.id);
        this.reorderGroups();
        (this.type === 'TPCGroupSubmission') ? this.submitTPCGroupSubmission(group.id) : this.submitTPCGroupMember(group.id);
        this.optionListChanges.emit(this.filteredOptions);
      });
      return;
    }
    this.resetForm();
  }

  private resetForm(): void {
    this.formGroupController.get(this.name).setValue(this.initialValue);
    this.setInitialValue();
  }

  private addOption(label: string, id: number): void {
    const option: SelectOption = new SelectOption(id, label);
    this.filteredOptions.push(option);
  }

  private reorderGroups(): void {
    this.removeNewGroupOption();
    this.sortOptions();
    this.addNewGroupOption();
  }

  private addNewGroupOption(): void {
    const labelNewGroup = this.translate.instant('submissions.create.group');
    this.addOption(labelNewGroup, -1);
  }

  private removeNewGroupOption(): void {
    this.filteredOptions = this.filteredOptions.filter(group => group.id !== -1);
  }

  private sortOptions(): void {
    this.filteredOptions = this.filteredOptions.sort((a, b) => a.value.localeCompare(b.value));
  }

  public removeGroupDialog(group: string): void {
    let dialogConfig: MatDialogConfig;
    let title: string;
    const params = { name: `"${group}"` };
    switch (this.type) {
      case 'paperGroup':
        title = 'admin.event.paper-groups.confirm-delete-group';
        dialogConfig = this.buildDialogConfig(title, params);
        break;
      case 'TPCGroupSubmission':
        title = 'admin.event.people.member-groups.confirm-delete-tpc-group';
        dialogConfig = this.buildDialogConfig(title, params);
        break;
      case 'TPCGroupMember':
        title = 'admin.event.people.member-groups.confirm-delete-tpc-group';
        dialogConfig = this.buildDialogConfig(title, params);
        break;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        switch (this.type) {
          case 'paperGroup':
            this.deletePaperGroup(group);
            break;
          case 'TPCGroupSubmission':
            this.deleteTPCGroup(group);
            break;
          case 'TPCGroupMember':
            this.deleteTPCGroup(group);
            break;
        }
      }
    });
  }

  private deletePaperGroup($group: string): void {
    const groupID = this.filteredOptions.find(group => group.value === $group).id;
    this.eventsService.deleteEventPaperGroup(this.event, groupID)
      .subscribe(() => {
        const title = 'submissions.edit.group-deleted';
        this.deleteGroupNotification(title, $group);
        this.filteredOptions = this.filteredOptions.filter(group => group.id !== groupID);
        this.optionListChanges.emit(this.filteredOptions);
      });
  }

  private deleteTPCGroup(TPCGroup: string): void {
    const groupID = this.filteredOptions.find(group => group.value === TPCGroup).id;
    this.eventsService.deleteEventTPCGroup(this.event, groupID)
      .subscribe(() => {
        const title = 'admin.event.people.member-groups.tpc-group-deleted';
        this.deleteGroupNotification(title, TPCGroup);
        this.filteredOptions = this.filteredOptions.filter(group => group.id !== groupID);
        this.optionListChanges.emit(this.filteredOptions);
      });
  }

  private getDisplayValue(value: any): string {
    let displayValue: any;
    this.filteredOptions.forEach((option: SelectOption) => {
      if ((option[this.indices.internal]) === value) {
        displayValue = `${this.prependPublic}${option[this.indices.public]}`;
      }
    });

    return displayValue;
  }

  public onClosed(): void {
    this.closed.emit(true);
  }
}
