import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectOption } from 'src/app/model/select.option';
import { FileRules } from 'src/app/model/file.rules';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { Submission } from 'src/app/model/paper';
import { MatSelectChange } from '@angular/material/select';
import { ChangeTrackData } from '../paper-info/paper-info.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { CacheService } from 'src/app/service/cache.service';
import { ContentObserver } from '@angular/cdk/observers';

const NONE = 'None';

@Component({
  selector: 'app-change-tracks-dialog',
  templateUrl: './change-tracks-dialog.component.html',
  styleUrls: ['./change-tracks-dialog.component.scss']
})
export class ChangeTracksDialogComponent implements OnInit {
  public targetTrackID: number;
  public targetTrackFiles: FileRules[] = [];
  public sourceTrackFiles: FileRules[] = [];

  public user_id : number;

  public trackFilesForm: FormGroup;
  public dictTrackFilesList: {} = {};

  private submissionID: number;
  constructor(
    private dialogRef: MatDialogRef<ChangeTracksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeTrackData,
    private fb: FormBuilder,
    private submissionService: SubmissionsService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private cache: CacheService
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateSubTitleWidth();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.updateSubTitleWidth();
    });
    this.submissionID = this.data.submissionID;
    this.targetTrackID = this.data.targetTrack.id;
    this.user_id = this.data.user_id;
    this.handlerTracks();
  }
    
  private handlerTracks() {
    this.sourceTrackFiles = this.data.tracks.find(track => track.id === this.data.sourceTrack.id).trackFiles;
    this.targetTrackFiles = this.data.tracks.find(track => track.id === this.data.targetTrack.id).trackFiles;
  

    this.submissionService.getTrackFilesMapping(this.targetTrackID, this.data.sourceTrack.id, this.user_id).subscribe(mapping => {
      Object.keys(mapping).forEach(key => {
        let Entry = mapping[key];
        this.cache.trackFileMapping.set(Entry.sourceFileId, Entry.targetFileId);
      });

      this.generateTrackFilesOptions(this.sourceTrackFiles, this.targetTrackFiles);
      this.initFormTrackFiles(this.sourceTrackFiles);
    })


  }

  private initFormTrackFiles(sourceTrackFiles: FileRules[]) {
    this.trackFilesForm = this.fb.group({});

    sourceTrackFiles.forEach(trackFile => {
        const controlName = this.buildNameControlForm(trackFile.name, trackFile.id);
        let controlValue = this.cache.trackFileMapping.get(controlName);

        if(!this.dictTrackFilesList[controlName].some(x => x.id == controlValue)){
          controlValue = NONE;
        }

        this.trackFilesForm.addControl(controlName, this.fb.control(controlValue));
    });
  }


  private generateTrackFilesOptions(sourceTrackFiles: FileRules[], targetTrackFiles: FileRules[]): void {
    for (let i in sourceTrackFiles) {
      const trackFileOptions = targetTrackFiles.map(trackFile => new SelectOption(trackFile.name + '$' + trackFile.id, trackFile.name, null, null, false));
      const controlFormName = this.buildNameControlForm(sourceTrackFiles[i].name, sourceTrackFiles[i].id);
      trackFileOptions.unshift(new SelectOption(NONE, NONE, null, null, false));
      this.dictTrackFilesList[controlFormName] = trackFileOptions;
    }
  }

  public onSelection($event: MatSelectChange) {
    const controlFormName: string = $event.source.ngControl.name as string;
    const value = $event.value;

    if (value != NONE) this.disabledOptionSelected(controlFormName, value);
  }

  private disabledOptionSelected(controlFormName: string, value: string): void {
    Object.keys(this.dictTrackFilesList).forEach(key => {
      if (key != controlFormName) {
        this.dictTrackFilesList[key] = this.dictTrackFilesList[key]
          .map((option: SelectOption) => new SelectOption(option.id, option.value, null, null, option.id === value));
      }
    });
  }

  public checkNoneOption(formValues: string): void {
    if (this.hasNoneOption(formValues)) {
      this.openWarningDialog();
      return;
    }

    this.checkChangeEventTrack();
  }

  public checkChangeEventTrack(): void {
    let oldEvent = this.data.oldEvent;
    let newEvent = this.data.newEvent;
    if (oldEvent != newEvent) {
      this.openWarningDialog(true);
      return;
    }

    this.submitChangeTracks();
  }

  private submitChangeTracks(): void {
    const data = {
      submission_id: this.submissionID,
      target_track_id: this.targetTrackID,
      swap: {}
    };

    const formValues = this.trackFilesForm.value;

    Object.keys(formValues).forEach(key => {
      if (formValues[key] != NONE) {
        data['swap'][key] = this.toCamelCase(formValues[key]);
        this.cache.trackFileMapping.set(this.data.sourceTrack.name + '-' + this.data.targetTrack.name + "-" + key, this.toCamelCase(formValues[key]));
        data['movedDescription'] = this.data.sourceTrack.name + ' to ' + this.data.targetTrack.name;
      }
      this.submissionService.updateTrackFilesMapping(this.user_id, this.targetTrackID, this.data.sourceTrack.id, key, formValues[key]).subscribe()
    });

    this.submissionService.changeTracks(data).subscribe(submission => {
      this.closeDialog(submission);
    });
  }

  public closeDialog(data: Submission): void {
    this.dialogRef.close(data);
  }

  private openWarningDialog(changeEvent?:boolean): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title: changeEvent? 'submissions.edit.warning.change-event-title' : 'submissions.edit.warning.option-none-selected-title',
      content: changeEvent? 'submissions.edit.warning.change-event-content' : 'submissions.edit.warning.option-none-selected-content',
    }

    const dialogWarningRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    dialogWarningRef.afterClosed().subscribe(isConfirmed => { 
      if (isConfirmed) {
        if (changeEvent) {
          this.submitChangeTracks();
        } else {
          this.checkChangeEventTrack();
        }
      }
    });
  }

  private hasNoneOption(formValues: {}): boolean {
    return Object.values(formValues).some(value => value === NONE);
  }

  public buildNameControlForm(name: string, id: number): string {
    return this.toCamelCase(name) + '$' + id;
  }

  private toCamelCase(name: string): string {
    return name.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  public hasFileForTrack(sourceTrackFileName: string): boolean {
    return this.data.tracksWithFile.includes(sourceTrackFileName);
  }

  public getTooltipForDisabledOption(): { disabled: string } {
    const message = this.translate.instant('submissions.edit.warning.track-file-already-selected');

    return { disabled: message };
  }

  private updateSubTitleWidth(): void{
    // This is called on the start and whenever the window is resized,
    // It makes the h3 element has always the same size of the normal-sized table
    const subTitleElement: HTMLElement | null = document.querySelector(".move-files-tracks .sub-title")
    const tableElement: HTMLElement | null = document.querySelector(".move-files-tracks .table-container table")
    if(subTitleElement && tableElement){
      subTitleElement.style.maxWidth = `${tableElement.offsetWidth}px`; // Set div1 width to match div2's width
    }
  }
}
