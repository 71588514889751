import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DeletedSubmission } from 'src/app/model/paper';
import { AdminService } from 'src/app/service/admin.service';
import { InfoService } from 'src/app/service/info.service';
import { NotificationService } from 'src/app/service/notification.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { SubmissionHistoryComponent } from '../submission-history/submission-history.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { Event } from 'src/app/model/event';
import * as dayjs from 'dayjs';


@Component({
  selector: 'app-deleted-submissions',
  templateUrl: './deleted-submissions.component.html',
  styleUrls: ['./deleted-submissions.component.scss']
})
export class DeletedSubmissionsComponent implements OnInit {
  event: Event;
  form: FormGroup;
  deletedSubmissions: Array<DeletedSubmission>;
  deletedSubmissionsDataSource = new MatTableDataSource<DeletedSubmission>();
  deletedTableColumns = ['id', 'previous-status', 'title', 'deleted-by', 'deleted-at', 'history', 'recovery', 'checkbox'];
  selectedStateMap: Map<number, boolean> = new Map();
  // The use of this list is for disabling and enabling the actions on the table's menu
  selectedList: Array<number> = [];

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.sort = sort;
    if (this.sort) {
      this.deletedSubmissionsDataSource.sort = this.sort;

      this.deletedSubmissionsDataSource.sortingDataAccessor = (submission: DeletedSubmission, header: string) => {
        switch (header) {
          case 'id': return submission.id;
          case 'previous-status': return submission.previousStatus;
          case 'title': return submission.title;
          case 'deleted-at': return submission.deletedAt.toString();
          case 'deleted-by': return submission.deletedBy.name;
          default: return submission.id;
        }
      };
    }
  }
  sort: MatSort;

  constructor(
    public adminService: AdminService,
    public submissionsService: SubmissionsService,
    public notificationService: NotificationService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public infoService: InfoService
  ) { }

  ngOnInit(): void {
    this.adminService.getEvent().subscribe(event=>{
      this.event=event;
      this.getRegisters();
    });
  }

  private selectAllSubmissions() {
    this.deletedSubmissions.forEach(submission => {
      this.selectedStateMap.set(submission.id, true);
    });
    this.setSubmissionsSelected();
  }

  private deselectAllSubmissions() {
    this.deletedSubmissions.forEach(submission => {
      this.selectedStateMap.set(submission.id, false);
    });
    this.setSubmissionsSelected();
  }

  getSelectedState(submissionId: number): boolean {
    return this.selectedStateMap.get(submissionId) || false; 
  }

  setSubmissionsSelected(){
    // This method is called every time the menu is opened
    this.selectedList = Array.from(this.selectedStateMap.entries())
    .filter(([id, selected]) => selected === true)
    .map(([id]) => id); 
  }
  
  onCheckboxChange(submissionId: number, isSelected: boolean) {
    this.selectedStateMap.set(submissionId, isSelected);
  }

  recoverSelectedSubmissions(): void {
    // Check if any submission was selected after the menu was opened
    this.setSubmissionsSelected();
    this.selectedList.forEach(s => {
      const submission = this.deletedSubmissions.filter(sub => sub.id = s)[0]
      this.recoverySubmission(submission.id, submission.previousStatus);
    })
  }

  getRegisters(): void {
    this.submissionsService.getDeletedSubmissions({event: this.event.id}).subscribe((deletedSubmissions) => {
      this.deletedSubmissions = deletedSubmissions.sort((a, b) =>  a.deletedAt.isBefore(b.deletedAt) ? 1 : -1);
      this.deletedSubmissionsDataSource.data = this.deletedSubmissions;
    });
  }

  getHistory(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '55rem';
    dialogConfig.height = '30rem';
    dialogConfig.panelClass = 'my-custom-dialog-class';
    dialogConfig.data = { id };
    this.dialog.open(SubmissionHistoryComponent, dialogConfig);
  }

  recoverySubmission(submissionId: number, status: string) {
    const data = {
      title: 'admin.deleted-submissions.notify.dialog-title',
      content: ''
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.submissionsService.recoverySubmission(submissionId, status).subscribe(() =>
          {
            this.notificationService.notify('admin.deleted-submissions.notify.done');
          },
          () => {
            this.getRegisters();
          }
        );
      }
    });
  }

  public get atLeastOneSelection() : Boolean {
    return this.selectedList.length > 0;
  }
}
