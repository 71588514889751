import { Component, OnInit } from '@angular/core';
import { InfoService } from '../../service/info.service';
import { EventsService } from '../../service/events.service';
import { Event } from '../../model/event';
import { PagedViewDirective } from 'src/app/class/paged-view';
import { NavbarService } from 'src/app/service/navbar.service';
import { UserEventRole } from '../../enum/user.event.role';
import { EventRequestStatus } from '../../enum/event.request.status';
import { ShowAllPagedViewDirective } from 'src/app/class/paged-view-all';
import { NO_PAGINATION } from 'src/app/model/pagination.options';
import { EventRequest } from 'src/app/model/eventRequest';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { PreferencesService } from 'src/app/service/preferences.service';
import { LocalCache } from 'src/app/enum/local.cache';
import { UserService } from 'src/app/service/user.service';
import { fork } from 'child_process';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-my-events',
  templateUrl: './my-events.component.html',
  styleUrls: ['./my-events.component.scss']
})
export class MyEventsComponent extends ShowAllPagedViewDirective {
  chairings: Array<Event>;
  requestedEvents: Array<EventRequest>;
  private userID: number;

  constructor(
    private infoService: InfoService,
    private eventsService: EventsService,
    public navbarService: NavbarService,
    public preferencesService: PreferencesService,
    public userService: UserService
  ) {
    super(preferencesService, LocalCache.OLD_CHAIRINGS);
  }

  load() {
    this.userID = this.infoService.user?.id;
    setTimeout(() => {
      this.navbarService.loadingStart();
      if (this.userID) {
        this.loadEvents();
        return;
      }

      this.userService.getUserFromToken().subscribe(u => {
        next: this.userID = u.id;
        complete: this.loadEvents();
      });
    });
  }

  private loadEvents() {
    forkJoin([
      this.getEventsByUserRole(),
      this.getRequestedEventByUser()
    ])
    .pipe(map(paginations => ({
      chairings: paginations[0].items as Event[],
      requests: paginations[1].items as EventRequest[]
    })))
    .subscribe(({ chairings, requests }) => {
      this.chairings = chairings;
      this.requestedEvents = requests;

      this.getSubEvents(this.chairings).subscribe(result =>{
        const subEvents = result.flat()
        this.chairings.map(event => {
          event.subEvents = subEvents.filter(child => child.eventParent == event.id);
        });        
        this.navbarService.loadingStop();
        window.scroll(0, 0);
      })
    });
  }

  getEventsByUserRole() {
    const paginationOptions = NO_PAGINATION;
    paginationOptions.show = this.showAll;

    return this.eventsService.getEventByUserRole(this.infoService.user?.id, UserEventRole.CHAIR, paginationOptions);
  }

  getRequestedEventByUser() {
    const paginationOptions = NO_PAGINATION;
    paginationOptions.show = this.showAll;
    
    return this.eventsService.getRequestedEventByUser(this.userID, paginationOptions);
  }
  private getSubEvents(elements: Event[]) {
    return forkJoin(elements.map(event=>
      this.eventsService.getAllSubEvents(event.id)
    ))
  }
}
