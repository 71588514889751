import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AdminService } from 'src/app/service/admin.service';
import { UserService } from 'src/app/service/user.service';
import { User } from 'src/app/model/user';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { MergeUsersRegister } from 'src/app/model/merge-users-register';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from 'src/app/service/notification.service';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { MatSort } from '@angular/material/sort';
import { InfoService } from 'src/app/service/info.service';
import { Submission, DeletedSubmission } from 'src/app/model/paper';
import { SubmissionHistoryComponent } from 'src/app/component/submission-history/submission-history.component';
import * as dayjs from 'dayjs';
import { setUncaughtExceptionCaptureCallback } from 'process';

@Component({
  selector: 'app-admin-deleted-submissions',
  templateUrl: './deleted-submissions.component.html',
  styleUrls: ['./deleted-submissions.component.scss']
})
export class AdminDeletedSubmissionsComponent implements OnInit {
  form: FormGroup;
  deletedSubmissions: Array<DeletedSubmission>;
  deletedSubmissionsDataSource = new MatTableDataSource<DeletedSubmission>();
  deletedTableColumns = ['id', 'previous-status', 'title', 'event', 'deleted-by', 'deleted-at', 'history', 'recovery', 'checkbox'];
  toggleSubmissions = false;
  selectedStateMap: Map<number, boolean> = new Map();

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.sort = sort;
    if (this.sort) {
      this.deletedSubmissionsDataSource.sort = this.sort;

      this.deletedSubmissionsDataSource.sortingDataAccessor = (submission: DeletedSubmission, header: string) => {
        switch (header) {
          case 'id': return submission.id;
          case 'previous-status': return submission.previousStatus;
          case 'title': return submission.title;
          case 'deleted-at': return submission.deletedAt.toString();
          case 'deleted-by': return submission.deletedBy.name;
          default: return submission.id;
        }
      };
    }
  }
  sort: MatSort;

  constructor(
    public adminService: AdminService,
    public submissionsService: SubmissionsService,
    public notificationService: NotificationService,
    public dialog: MatDialog,
    public fb: FormBuilder,
    public infoService: InfoService
  ) { }

  ngOnInit(): void {
    this.getRegisters();
  }

  toggleAllSubmissions(){
    this.toggleSubmissions = !this.toggleSubmissions;
    this.deletedSubmissions.forEach(submission => {
      this.selectedStateMap.set(submission.id, this.toggleSubmissions);
    });
  }

  getSelectedState(submissionId: number): boolean {
    return this.selectedStateMap.get(submissionId) || false; 
  }

  getSubmissionsSelected(){
    const selectedSubmissions = Array.from(this.selectedStateMap.entries())
    .filter(([id, selected]) => selected === true)
    .map(([id]) => id); 

    return selectedSubmissions;
  }
  
  onCheckboxChange(submissionId: number, isSelected: boolean) {
    this.selectedStateMap.set(submissionId, isSelected);
  }

  permanentlyDeleteSubmission(submission_id: number, delete_reason: string): void {
    this.submissionsService.permanentlyDeleteSubmission(submission_id, delete_reason).subscribe(() => {
      this.deletedSubmissions = this.deletedSubmissions.filter(submission => submission.id !== submission_id);
      this.deletedSubmissionsDataSource.data = this.deletedSubmissions;  
    });
  }

  permanentlydeleteSelectedSubmissions(): void {
    const selectedSubmissions = this.getSubmissionsSelected();
    selectedSubmissions.forEach(id => {
      this.permanentlyDeleteSubmission(id, '');
    })
  }

  permanentlydeleteSubmissionsOlderThanThreeMonths(): void {
    const submissionsOlderThanThreeMonths = this.deletedSubmissions.filter(submission => submission.deletedAt < dayjs().subtract(3, 'month'));
    submissionsOlderThanThreeMonths.forEach(submission => {
      this.permanentlyDeleteSubmission(submission.id, "");
    });
  }

  permanentlydeleteAllSubmissions(): void {
    this.deletedSubmissions.forEach(submission => {
      this.permanentlyDeleteSubmission(submission.id, "");
    });
  }
  

  getRegisters(): void {
    this.submissionsService.getDeletedSubmissions().subscribe((deletedSubmissions) => {
      this.deletedSubmissions = deletedSubmissions.sort((a, b) =>  a.deletedAt.isBefore(b.deletedAt) ? 1 : -1);
      this.deletedSubmissionsDataSource.data = this.deletedSubmissions;
    });
  }

  getHistory(id: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '55rem';
    dialogConfig.height = '30rem';
    dialogConfig.panelClass = 'my-custom-dialog-class';
    dialogConfig.data = { id };
    this.dialog.open(SubmissionHistoryComponent, dialogConfig);
  }

  recoverySubmission(submissionId: number, status: string) {
    const data = {
      title: 'admin.deleted-submissions.notify.dialog-title',
      content: ''
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.submissionsService.recoverySubmission(submissionId, status).subscribe(() =>
          {
            this.notificationService.notify('admin.deleted-submissions.notify.done');
          },
          () => {
            this.getRegisters();
          }
        );
      }
    });
  }
}
