import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-email',
  templateUrl: './edit-email.component.html',
  styleUrls: ['./edit-email.component.scss']
})
export class EditEmailComponent implements OnInit {
  private textForm: FormGroup;
  rows: number = 6; 

  constructor(
    private dialogRef: MatDialogRef<EditEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {email: string, emailTitle: string, emailBody: string},
    protected fb: FormBuilder
  ) { }

  ngOnInit(): void { 
    this.textForm = this.fb.group({
      email: [this.data.email],
      emailTitle: [this.data.emailTitle],
      emailBody: [this.data.emailBody]
    });
    this.updateRows(window.innerWidth, window.innerHeight);
  }

  private cancelSending(): void {
    this.dialogRef.close();
  }

  private confirmSending(): void{
    this.dialogRef.close({emailTitle: this.textForm.value.emailTitle, emailBody: this.textForm.value.emailBody});
  }
 
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateRows(event.target.innerWidth, event.target.innerHeight);
  }

  updateRows(width: number, height: number) {
    this.rows = Math.ceil(height / 100);
  }
}
