import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Environment } from '../environment/environment';
import { Event } from '../model/event';
import { CheckListField } from '../model/checklist-field';
import { EventInfo } from '../model/eventInfo';
import { Observable, throwError, of as ObservableFrom, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { plainToClass, plainToClassFromExist, classToPlain } from 'class-transformer';
import { Response } from '../model/response';
import { EventTopic, EventTopicOptions, DEFAULT_EVENT_TOPIC_OPTIONS, SHOW_ALL_EVENT_TOPIC_OPTIONS } from '../model/eventTopic';
import { Form } from '../model/form';
import { Track } from '../model/track';
import { Session } from '../model/session';
import { EmailRegister } from '../model/emailRegister';
import { CacheService } from './cache.service';
import { EventType } from '../model/eventType';
import { Workflow } from '../model/workflow';
import { EventRequest } from '../model/eventRequest';
import { EventRequestStatus } from '../enum/event.request.status';
import { UserEventRole } from '../enum/user.event.role';
import { UserEvent } from '../model/user.event';
import { InfoService } from './info.service';
import { Taxonomy } from '../model/taxonomy';
import { PaperGroup } from '../model/paper.group';
import { TPCGroup } from '../model/tpc.group';
import { NotificationEmail } from '../model/notificationEmail';
import { Pagination } from '../model/pagination';
import { DEFAULT_PAGINATION_OPTIONS, PaginationOptions } from '../model/pagination.options';
import { EmailTrigger } from '../enum/email.trigger';
import { UserEventAnswer } from '../enum/user.event.answer';
import { User } from '../model/user';
import { Profile } from '../model/profile';
import { Affiliation } from '../model/affiliation';
import { UserEventTopicsInterest } from '../model/user.event.topics.interest';
import { SubmissionFieldAndFilter } from '../model/submissionFieldFilter';
import { FileType } from '../model/fileType';
import { FormType } from '../enum/form.type';
import { Submission } from '../model/paper';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  public events: Observable<Event[]>;

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private cache: CacheService
  ) { }

  getAllEvents(options: PaginationOptions = DEFAULT_PAGINATION_OPTIONS): Observable<Pagination<Event>> {
    const params = { params: {} };

    if (options.pageNumber) {
      params.params['pageNumber'] = String(options.pageNumber);
    }

    if (options.pageSize) {
      params.params['pageSize'] = String(options.pageSize);
    }

    if (options.show) {
      params.params['show'] = String(options.show);
    }


    return this.http.get<Response>(Environment.urls.API + '/core/event/', params)
      .pipe(map(v => plainToClassFromExist(new Pagination<Event>(Event), v.data)))
      .pipe(map(v => {
        this.cache.setAll(
          this.cache.events,
          v.items.map(event => ({ key: event.id.toString(), value: event }))
        );

        return v;
      }));
  }

  getAllEventsWithMinimumDetails(options: PaginationOptions = DEFAULT_PAGINATION_OPTIONS): Observable<Array<Event>> {
    const params = { params: {} };

    if (options.show) {
      params.params['show'] = String(options.show);
    }

    return this.http.get<Response>(Environment.urls.API + '/core/event/all/', params)
      .pipe(map(v => plainToClass(Event, v.data as Array<Event>)));
  }

  getAllEventsWithoutParent(): Observable<Array<Event>> {
    return this.http.get<Response>(Environment.urls.API + '/core/event/allWithoutParent/')
      .pipe(map(v => plainToClass(Event, v.data as Array<Event>)));
  }

  getEvent(id: number): Observable<Event> {
    if (id) {
      return this.http.get<Response>(Environment.urls.API + '/core/event/' + id + '/')
        .pipe(map(v => plainToClass(Event, v.data as Event)))
        .pipe(map(event => {
          event.tracks.forEach(track => {
            this.cache.setValue(this.cache.eventsByTrack, track.id.toString(), event);
          });

          return this.cache.setValue(this.cache.events, event.id.toString(), event).get(event.id.toString());
        }));
    }
  }

  getEventSettings(id: number): Observable<Event> {

    if (id) {
      return this.http.get<Response>(Environment.urls.API + '/core/event/' + id + '/settings/')
        .pipe(map(v => plainToClass(Event, v.data as Event)))
    }
  }

  getSubconferencesByEvent(event_id: number): Observable<Array<Event>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${event_id}/subconferences/`)
      .pipe(map(v => plainToClass(Event, v.data as Array<Event>)));
  }

  getAllSubEvents(eventID: number): Observable<Array<Event>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/allSubEvents/${eventID}/`)
      .pipe(map(v => plainToClass(Event, v.data as Array<Event>)));
  }

  getAllSubEventsTracks(eventID: number): Observable<Array<Track>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/allSubEventsTracks/${eventID}/`)
      .pipe(map(v => plainToClass(Track, v.data as Array<Track>)));
  }

  getEventByTrack(trackID: number): Observable<Event> {
    const foundEvent = this.cache.getValue(this.cache.eventsByTrack, trackID.toString());

    if (foundEvent) {
      return ObservableFrom(foundEvent);
    } else {
      return this.http.get<Response>(Environment.urls.API + '/core/event/byTrack/' + trackID + '/')
        .pipe(map(v => plainToClass(Event, v.data as Event)))
        .pipe(map(event => {
          this.cache.setValue(this.cache.events, event.id.toString(), event);
          this.cache.setValue(this.cache.eventsByTrack, trackID.toString(), event);
          return event;
        }));
    }
  }

  getEventInfo(eventInfoID: number): Observable<EventInfo> {
    const foundInfo = this.cache.getValue(this.cache.eventsInfo, eventInfoID.toString());

    if (foundInfo) {
      return ObservableFrom(foundInfo);
    } else {
      return this.http.get<Response>(Environment.urls.API + '/core/eventInfo/' + eventInfoID + '/')
        .pipe(map(v => plainToClass(EventInfo, v.data as EventInfo)))
        .pipe(map(eventInfo =>
          this.cache
            .setValue(this.cache.eventsInfo, eventInfo.id.toString(), eventInfo)
            .get(eventInfo.id.toString())
        ));
    }
  }

  getEventTopics(eventID: number, options: EventTopicOptions = DEFAULT_EVENT_TOPIC_OPTIONS): Observable<EventTopic[]> {
    const params = { params: {} };

    if (options.show) {
      params.params['show'] = String(options.show);
    }

    const foundEvent = this.cache.getValue(this.cache.events, eventID.toString());

    if (foundEvent ?.topics) {
      const foundTopics = [];

      foundEvent.topics.every(topicID => {
        const foundTopic = this.cache.getValue(this.cache.topics, topicID.toString());

        if (foundTopic) {
          foundTopics.push(foundTopic);
        }
        return !!foundTopic;
      });

      if (foundTopics.length === foundEvent.topics.length) {
        return ObservableFrom(foundTopics);
      }
    }

    return this.http.get<Response>(Environment.urls.API + '/core/eventTopic/byEvent/' + eventID + '/', params)
      .pipe(map(v => plainToClass(EventTopic, v.data as EventTopic[])))
      .pipe(map(topics => {
        this.cache.setAll(
          this.cache.topics,
          topics.map(topic => ({ key: topic.id.toString(), value: topic }))
        );

        return topics;
      }));
  }

  getSubmissionFilesByTrack(trackId: number, trackFiles: Array<number>): Observable<{ link: string }> {
    const params = {};
    params['params'] = {
      trackFiles: trackFiles
    };

    return this.http.get<Response>(`${Environment.urls.API}/core/track/submissionFiles/${trackId}/`, params)
      .pipe(map(r => ({ link: `${Environment.urls.API}/${r.data['link']}` })));
  }

  getSolSpreadsheets(eventId: number, proceedings: Array<number>, relationsTrackFileSection: Array<any[]>): Observable<{ link?: string, articlesNotUsingSbcTemplate?: Array<any> }> {
    const params = {};
    params['params'] = {
      proceedings: proceedings,
      relationsTrackFileSection: relationsTrackFileSection
    };

    return this.http.get<Response>(`${Environment.urls.API}/core/event/solSpreadsheets/${eventId}/`, params)
      .pipe(map(r => {
        if (r.data['link'])
          return { link: `${Environment.urls.API}/${r.data['link']}` };

        return { articlesNotUsingSbcTemplate: r.data['articlesNotUsingSbcTemplate'] };
      }));
  }

  getSolSections(seriesPath: string): Observable<Object[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/solSections/${seriesPath}/`)
      .pipe(map(v => v.data as Object[]));
  }

  downloadSolSpreadsheets(fileURL: string):  Observable<any> {
    return this.http.get<Response>(fileURL, {responseType: 'blob' as 'json'});
  }

  putEventTopics(eventID: number, topics: EventTopic[]): Observable<EventTopic[]> {
    return this.http.put<Response>(Environment.urls.API + '/core/eventTopic/byEvent/' + eventID + '/', topics
    )
      .pipe(map(v => plainToClass(EventTopic, v.data as EventTopic[])))
      .pipe(map(v => {
        this.cache.setAll(
          this.cache.topics,
          v.map(topic => ({ key: topic.id.toString(), value: topic }))
        );

        const foundEvent = this.cache.getValue(this.cache.events, eventID.toString());
        if (foundEvent) {
          foundEvent.topics = v;
          // TODO: if topics is removed from EventSerializer, this has to switch to only the ids of the topics
          this.cache.setValue(this.cache.events, eventID.toString(), foundEvent);
        }
        return v;
      }));
  }

  deleteEventTopic(topic: number): Observable<Response> {
    return this.http.delete<Response>(Environment.urls.API + '/core/eventTopic/' + topic + '/');
  }

  getFormSubmission(eventID: number, requestedForm?: number | Form): Observable<Form> {
    if (requestedForm) {
      const foundForm = this.cache.getValue(this.cache.forms, requestedForm instanceof Form ?
        requestedForm.id.toString() :
        requestedForm.toString()
      );

      if (foundForm) {
        return ObservableFrom(foundForm);
      }
    }

    return this.http.get<Response>(Environment.urls.API + '/core/form/submission/byEvent/' + eventID + '/')
      .pipe(map(v => plainToClass(Form, v?.data as Form)))
      .pipe(map(form =>
        this.cache
          .setValue(this.cache.forms, form?.id.toString(), form)
          .get(form?.id.toString())
      ));
  }

  getFormReview(eventID: number, formID?: number): Observable<Form> {
    if (formID) {
      const foundForm = this.cache.getValue(this.cache.forms, formID.toString());

      if (foundForm) {
        return ObservableFrom(foundForm);
      }
    }

    return this.http.get<Response>(Environment.urls.API + '/core/form/review/byEvent/' + eventID + '/')
      .pipe(map(v => plainToClass(Form, v?.data as Form)))
      .pipe(map(form =>
        this.cache
          .setValue(this.cache.forms, form?.id.toString(), form)
          .get(form?.id.toString())
      ));
  }

  getForm(formType: FormType, eventId: number, formID?: number): Observable<Form> {
    if (formType === FormType.SUBMISSION) {
      return this.getFormSubmission(eventId, formID);
    }
    if (formType === FormType.REVIEW) {
      return this.getFormReview(eventId, formID);
    }
    // No behavior defined for other FormTypes
  }

  createForm(params): Observable<Form> {
    return this.http.post<Response>(Environment.urls.API + `/core/form/complete/`, params)
      .pipe(map(v => plainToClass(Form, v.data as Form)))
      .pipe(map(form =>
        this.cache
          .setValue(this.cache.forms, form.id.toString(), form)
          .get(form.id.toString())
      ));

  }

  editForm(params, formId: number): Observable<Form> {
    return this.http.put<Response>(Environment.urls.API + `/core/form/complete/${formId}/`, params)
      .pipe(map(v => plainToClass(Form, v.data as Form)))
      .pipe(map(form =>
        this.cache
          .setValue(this.cache.forms, form.id.toString(), form)
          .get(form.id.toString())
      ));

  }

  getTrack(trackID: number): Observable<Track> {
    const foundTrack = this.cache.getValue(this.cache.tracks, trackID.toString());

    if (foundTrack) {
      return ObservableFrom(foundTrack);
    } else {
      return this.http.get<Response>(`${Environment.urls.API}/core/track/${trackID}/`)
        .pipe(map(v => plainToClass(Track, v.data as Track)))
        .pipe(map(track =>
          this.cache
            .setValue(this.cache.tracks, track.id.toString(), track)
            .get(track.id.toString())
        ));
    }
  }

  deleteTrack(trackId: number, event: Event): Observable<Event> {
    return this.http.delete<Response>(Environment.urls.API + `/core/track/${trackId}/`)
      .pipe(map(() => {
        event.tracks = event.tracks.filter(track => track.id !== trackId);
        event.tracks.forEach(track =>
          this.cache.setValue(this.cache.tracks, track.id.toString(), track)
        );

        return this.cache.setValue(
          this.cache.events, event.id.toString(), event
        ).get(event.id.toString());
      }));
  }

  deleteTrackFile(trackFileId: number): Observable<Response> {
    return this.http.delete<Response>(Environment.urls.API + `/core/trackFile/${trackFileId}/`);
  }

  createTrack(params): Observable<Track> {
    return this.http.post<Response>(Environment.urls.API + `/core/track/`, params)
      .pipe(map(v => plainToClass(Track, v.data as Track)))
      .pipe(map(track =>
        this.cache
          .setValue(this.cache.tracks, track.id.toString(), track)
          .get(track.id.toString())
      ));
  }

  editTrack(params, trackId: number): Observable<Track> {
    return this.http.put<Response>(Environment.urls.API + `/core/track/${trackId}/`, params)
      .pipe(map(v => plainToClass(Track, v.data as Track)))
      .pipe(map(track => {
        this.getEvent(track.event).subscribe(e => {
          const i = e.tracks.findIndex(t => t.id === track.id);
          e.tracks[i] = track;

          this.cache.setValue(this.cache.events, track.event.toString(), e);
        });

        return this.cache
          .setValue(this.cache.tracks, track.id.toString(), track)
          .get(track.id.toString());
      }));
  }

  getTrackCheckListFields(trackId: number): Observable<Array<CheckListField>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/track/${trackId}/checklist/`)
      .pipe(map(v => plainToClass(CheckListField, v.data as Array<CheckListField>)));
  }

  createTrackCheckList(params: Array<CheckListField>, trackId: number): Observable<Array<CheckListField>> {
    return this.http.post<Response>(`${Environment.urls.API}/core/track/${trackId}/checklist/`, params)
      .pipe(map(v => plainToClass(CheckListField, v.data as Array<CheckListField>)));
  }

  editTrackCheckList(params: Array<CheckListField>, trackId: number): Observable<Array<CheckListField>> {
    return this.http.put<Response>(`${Environment.urls.API}/core/track/${trackId}/checklist/`, params)
      .pipe(map(v => plainToClass(CheckListField, v.data as Array<CheckListField>)));
  }

  deleteTrackCheckList(checklistId: number, trackId: number): Observable<Array<CheckListField>> {
    return this.http.delete<Response>(`${Environment.urls.API}/core/track/${trackId}/checklist/${checklistId}/`)
      .pipe(map(v => plainToClass(CheckListField, v.data as Array<CheckListField>)));
  }

  getSubmissionCheckListAnswers(submissionId: number): Observable<Array<CheckListField>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/submission/${submissionId}/checklist/`)
      .pipe(map(v => plainToClass(CheckListField, v.data as Array<CheckListField>)));
  }

  editSubmissionCheckListAnswers(params, submissionId: number): Observable<Array<CheckListField>> {
    return this.http.put<Response>(`${Environment.urls.API}/core/submission/${submissionId}/checklist/`, params)
      .pipe(map(v => plainToClass(CheckListField, v.data as Array<CheckListField>)));
  }

  createSubmissionCheckListAnswers(params, submissionId: number): Observable<Array<CheckListField>> {
    return this.http.post<Response>(`${Environment.urls.API}/core/submission/${submissionId}/checklist/`, params)
      .pipe(map(v => plainToClass(CheckListField, v.data as Array<CheckListField>)));
  }

  getSessionsByEvent(eventId: number): Observable<Session[]> {
    return this.http.get<Response>(Environment.urls.API + `/core/session/byEvent/${eventId}/`)
      .pipe(map(v => plainToClass(Session, v.data as Array<Session>)));
  }

  getEmailStatusByEvent(eventId: number): Observable<EmailRegister[]> {
    return this.http.get<Response>(Environment.urls.API + `/core/emailStatus/byEvent/${eventId}/`)
      .pipe(map(v => plainToClass(EmailRegister, v.data as Array<EmailRegister>)));
  }

  resendEmail(eventId: number, emailId: number): Observable<EmailRegister[]> {
    return this.http.post<Response>(Environment.urls.API + `/core/resendemail/`, { event_id: eventId, email_id: emailId })
      .pipe(map(v => plainToClass(EmailRegister, v.data as EmailRegister[])));
  }

  createSession(params): Observable<Session> {
    return this.http.post<Response>(Environment.urls.API + `/core/session/`, params)
      .pipe(map(v => plainToClass(Session, v.data as Session)));
  }

  editSession(params, sessionId: number): Observable<Session> {
    return this.http.put<Response>(Environment.urls.API + `/core/session/${sessionId}/`, params)
      .pipe(map(v => plainToClass(Session, v.data as Session)));
  }

  deleteSession(sessionId: number, event: Event): Observable<Response> {
    return this.http.delete<Response>(Environment.urls.API + `/core/session/${sessionId}/`);
  }

  createEvent(params): Observable<Event> {
    return this.http.post<Response>(Environment.urls.API + `/core/event/`, params)
      .pipe(map(v => plainToClass(Event, v.data as Event)))
      .pipe(map(event =>
        this.cache
          .setValue(this.cache.events, event.id.toString(), event)
          .get(event.id.toString())
      ));
  }

  createEventInfo(params): Observable<EventInfo> {
    return this.http.post<Response>(Environment.urls.API + '/core/eventInfo/', params)
      .pipe(map(v => plainToClass(EventInfo, v.data as EventInfo)));
  }

  getEventTypes(): Observable<EventType[]> {
    return this.http.get<Response>(Environment.urls.API + '/core/eventType/')
      .pipe(map(v => plainToClass(EventType, v.data as EventType[])))
      .pipe(map(types => {
        this.cache.setAll(
          this.cache.eventTypes,
          types.map(type => ({ key: type.id.toString(), value: type }))
        );

        return this.cache.getAll(this.cache.eventTypes);
      }));
  }

  getWorkflows(): Observable<Workflow[]> {
    return this.http.get<Response>(Environment.urls.API + '/core/workflow/')
      .pipe(map(v => plainToClass(Workflow, v.data as Workflow[])))
      .pipe(map(workflows => {
        this.cache.setAll(
          this.cache.workflows,
          workflows.map(flow => ({ key: flow.id.toString(), value: flow }))
        );

        return this.cache.getAll(this.cache.workflows);
      }));
  }

  getWorkflow(workflowID: number): Observable<Workflow> {
    const foundWorkflow = this.cache.getValue(this.cache.workflows, workflowID.toString());

    if (foundWorkflow) {
      return ObservableFrom(foundWorkflow);
    }
    return this.getWorkflows().pipe(map(workflows => workflows.find(flow => flow.id === workflowID)));
  }

  getTopic(topicID: number): Observable<EventTopic> {
    const foundTopic = this.cache.getValue(this.cache.topics, topicID.toString());

    if (foundTopic) {
      return ObservableFrom(foundTopic);
    }

    return this.http.get<Response>(`${Environment.urls.API}/core/topic/${topicID}/`)
      .pipe(map(v => plainToClass(EventTopic, v.data as EventTopic)))
      .pipe(map(topic =>
        this.cache
          .setValue(this.cache.topics, topic.id.toString(), topic)
          .get(topic.id.toString())
      ));
  }

  requestEventCreation(eventRequest: EventRequest): Observable<EventRequest> {
    return this.http.post<Response>(`${Environment.urls.API}/core/event/request/`, eventRequest)
      .pipe(map(v => plainToClass(EventRequest, v.data as EventRequest)));
  }

  getAllEventRequests(status: EventRequestStatus = EventRequestStatus.PENDING): Observable<EventRequest[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/request/?status=${status}`)
      .pipe(map(v => plainToClass(EventRequest, v.data as EventRequest[])));
  }

  getEventRequest(id: number): Observable<EventRequest> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/request/${id}/`)
      .pipe(map(v => plainToClass(EventRequest, v.data as EventRequest)));
  }

  editEventRequest(eventRequest: EventRequest): Observable<EventRequest> {
    return this.http.put<Response>(`${Environment.urls.API}/core/event/request/${eventRequest.id}/`, eventRequest)
      .pipe(map(v => plainToClass(EventRequest, v.data as EventRequest)));
  }

  isAuthenticated(): boolean {
    // COPY of authService isAuthenticated.
    // Circular dependency fix.
    let validToken = false;

    try {
      validToken = !this.jwtHelper.isTokenExpired(localStorage.getItem('token'));
    } catch (error) {
      validToken = false;
    }

    return validToken;
  }


  getEventByLink(link: string): Observable<Event> {
    if (!this.isAuthenticated()) {
      return throwError(link);
    }

    return this.http.get<Response>(`${Environment.urls.API}/core/event/byURI/${link}/`)
      .pipe(map(v => plainToClass(Event, v.data as Event)))
      .pipe(map(event => event ?
        this.cache
          .setValue(this.cache.events, event.id.toString(), event)
          .get(event.id.toString()) : undefined
      ));
  }

  getUsedEventURI(link: string): Observable<boolean> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/usedURI/${link}/`)
      .pipe(map(v => v.data as boolean));
  }

  editEvent(id: number, params): Observable<Event> {
    return this.http.put<Response>(`${Environment.urls.API}/core/event/${id}/`, params)
      .pipe(map(v => plainToClass(Event, v.data as Event)))
      .pipe(map(event => event ?
        this.cache
          .setValue(this.cache.events, event.id.toString(), event)
          .get(event.id.toString()) : undefined
      ));
  }

  editEventInfo(id: number, params): Observable<EventInfo> {
    return this.http.put<Response>(`${Environment.urls.API}/core/eventInfo/${id}/`, params)
      .pipe(map(v => plainToClass(EventInfo, v.data as EventInfo)))
      .pipe(map(eventInfo => eventInfo ?
        this.cache
          .setValue(this.cache.eventsInfo, eventInfo.id.toString(), eventInfo)
          .get(eventInfo.id.toString()) : undefined
      ));
  }

  getEventByUserRole(
    id: number,
    role: UserEventRole,
    options: PaginationOptions = DEFAULT_PAGINATION_OPTIONS
  ): Observable<Pagination<Event>> {
    const params = { params: {} };

    if (options.pageNumber) {
      params.params['pageNumber'] = String(options.pageNumber);
    }

    if (options.pageSize) {
      params.params['pageSize'] = String(options.pageSize);
    }

    if (options.show) {
      params.params['show'] = String(options.show);
    }

    return this.http.get<Response>(`${Environment.urls.API}/core/event/byUserRole/${id}/${role}/`, params)
      .pipe(map(v => plainToClassFromExist(new Pagination<Event>(Event), v.data)))
      .pipe(map(v => {
        this.cache.setAll(
          this.cache.events,
          v.items.map(event => ({ key: event.id.toString(), value: event }))
        );

        return v;
      }));
  }

  getRequestedEventByUser(
    user: number,
    options: PaginationOptions = DEFAULT_PAGINATION_OPTIONS,
    status: EventRequestStatus = EventRequestStatus.PENDING
  ): Observable<Pagination<EventRequest>> {
    const params = { params: {} };

    if (options.pageNumber) {
      params.params['pageNumber'] = String(options.pageNumber);
    }

    if (options.pageSize) {
      params.params['pageSize'] = String(options.pageSize);
    }

    params.params['status'] = String(status);

    return this.http.get<Response>(`${Environment.urls.API}/core/event/request/byUser/${user}/`, params)
      .pipe(map(v => plainToClassFromExist(new Pagination<EventRequest>(EventRequest), v.data)));
  }

  getTaxonomies(): Observable<Taxonomy[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/taxonomy/`)
      .pipe(map(v => plainToClass(Taxonomy, v.data as Taxonomy[])))
      .pipe(
        map(taxonomies => {
          this.cache.setAll(
            this.cache.taxonomies,
            taxonomies.map(taxonomy => ({ key: taxonomy.id.toString(), value: taxonomy }))
          );

          // Cache of inner taxonomies.
          taxonomies.forEach(t => {
            if (t.taxonomies && t.taxonomies.length > 0) {
              this.cache.setAll(
                this.cache.taxonomies,
                t.taxonomies.map(taxonomy => ({ key: taxonomy.id.toString(), value: taxonomy }))
              );
            }
          });

          return taxonomies;
        })
      );
  }

  getTaxonomy(taxonomyID: number): Observable<Taxonomy> {
    const foundTaxonomy = this.cache.getValue(this.cache.taxonomies, taxonomyID.toString());

    if (foundTaxonomy) {
      return ObservableFrom(foundTaxonomy);
    }
    return this.http.get<Response>(`${Environment.urls.API}/core/taxonomy/${taxonomyID}/`)
      .pipe(map(v => plainToClass(Taxonomy, v.data as Taxonomy)))
      .pipe(map(taxonomy => this.cache
        .setValue(this.cache.taxonomies, taxonomy.id.toString(), taxonomy)
        .get(taxonomy.id.toString()))
      );
  }

  editEventTopics(eventID: number, topics: string[]) {
    return this.http.put<Response>(`${Environment.urls.API}/core/event/${eventID}/`, { topics })
      .pipe(map(v => plainToClass(Event, v.data as Event)))
      .pipe(map(event =>
        this.cache
          .setValue(this.cache.events, event.id.toString(), event)
          .get(event.id.toString())
      ));
  }

  getEventPeopleByRole(event: number, role: UserEventRole, invitationAnswer = null): Observable<Array<UserEvent>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${event}/people/`, {
      params: this.formatParamsToGetPeople(role, invitationAnswer)
    }).pipe(map(v => plainToClass(UserEvent, v.data as Array<UserEvent>)));
  }

  private formatParamsToGetPeople(role: UserEventRole, invitationAnswer) {
    if (invitationAnswer) {
      return { role, answer: invitationAnswer.toUpperCase() };
    }
    return { role };
  }

  getEventMinimalPeopleByRole(event: number, role: UserEventRole): Observable<Array<UserEvent>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${event}/peopleMinimal/`, {
      params: { role }
    }).pipe(map(v => plainToClass(UserEvent, v.data as Array<UserEvent>)));
  }

  getUserEventListByRole(event: number, role: UserEventRole): Observable<Array<UserEvent>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${event}/userEventList/`, {
      params: { role }
    }).pipe(map(v => this.userEventListToUserEvent(v.data)));
  }

  getEventChairs(event: number): Observable<Array<UserEvent>> {
    return this.getEventPeopleByRole(event, UserEventRole.CHAIR);
  }

  getEventCommitteeList(event: number): Observable<Array<UserEvent>> {
    return this.getUserEventListByRole(event, UserEventRole.COMMITTEE)
      .pipe(map(p => p.filter(e =>
        e.invitationAnswer.toUpperCase() === UserEventAnswer.ACCEPTED.toUpperCase())
      ));
  }

  getEventCommittee(event: number, invitationAnswer: string = null): Observable<Array<UserEvent>> {
    return this.getEventPeopleByRole(event, UserEventRole.COMMITTEE, invitationAnswer);
  }

  getEventCommitteeByTPCGroup(event: number, invitationAnswer: string = null, groupID: number): Observable<Array<UserEvent>> {
    return this.getEventPeopleByRole(event, UserEventRole.COMMITTEE, invitationAnswer)
            .pipe(map(p => p.filter(e => e.tpcGroup?.id === groupID)));
  }

  getUserIsUserEvent(event: number, role: UserEventRole, user:number):Observable<boolean> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${event}/userIsChairOrCommittee/`, {
      params: { role, 'user': String(user)}
    }).pipe(map(bool => <boolean>bool.data)
    );
  }

  getEventPaperGroups(event: number): Observable<Array<PaperGroup>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${event}/groups/paper/`)
      .pipe(map(v => plainToClass(PaperGroup, v.data as Array<PaperGroup>)));
  }

  deleteEventPaperGroup(event: number, group: number): Observable<Array<PaperGroup>> {
    return this.http.delete<Response>(`${Environment.urls.API}/core/event/${event}/groups/paper/` + group + '/')
      .pipe(map(v => plainToClass(PaperGroup, v.data as Array<PaperGroup>)));
  }

  addEventPaperGroup(event: number, name: string): Observable<PaperGroup> {
    return this.http.post<Response>(`${Environment.urls.API}/core/event/${event}/groups/paper/`, { name, event })
      .pipe(map(v => plainToClass(PaperGroup, v.data as PaperGroup)));
  }

  getEventTPCGroups(event: number, details?: string): Observable<Array<TPCGroup>> {
    const params = { params: { details } };
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${event}/groups/tpc/`, params)
      .pipe(map(v => plainToClass(TPCGroup, v.data as Array<TPCGroup>)));
  }

  deleteEventTPCGroup(event: number, group: number): Observable<Array<TPCGroup>> {
    return this.http.delete<Response>(`${Environment.urls.API}/core/event/${event}/groups/tpc/` + group + '/')
      .pipe(map(v => plainToClass(TPCGroup, v.data as Array<TPCGroup>)));
  }

  addEventTPCGroup(event: number, name: string): Observable<TPCGroup> {
    return this.http.post<Response>(`${Environment.urls.API}/core/event/${event}/groups/tpc/`, { name, event })
      .pipe(map(v => plainToClass(TPCGroup, v.data as TPCGroup)));
  }

  getEventPeople(event: number): Observable<Array<UserEvent>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${event}/people/`)
      .pipe(map(v => plainToClass(UserEvent, v.data as Array<UserEvent>)));
  }

  getEventCoChairsNotification(event: number): Observable<Response> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${event}/coChairsNotification/`)
      .pipe(map(v => v.data));
  }

  editEventCoChairsNotification(event: number, params): Observable<Response> {
    return this.http.put<Response>(`${Environment.urls.API}/core/event/${event}/coChairsNotification/`, params)
      .pipe(map(v => v.data));
  }

  editEventPeople(member: UserEvent, event: number): Observable<UserEvent> {
    return this.http.put<Response>(`${Environment.urls.API}/core/event/${event}/people/${member.id}/`, member)
      .pipe(map(v => plainToClass(UserEvent, v.data)));
  }

  getEventPeopleWithoutTPCGroup(event: number): Observable<Array<UserEvent>> {
    return this.getEventCommittee(event).pipe(map(v => v.filter(user => !user.tpcGroup)));
  }

  setUserEvent(
    event: number, user: number, role: UserEventRole,
    answer: UserEventAnswer = UserEventAnswer.NOT_ANSWERED
  ): Observable<UserEvent> {
    return this.http.post<Response>(`${Environment.urls.API}/core/userEvent/`,
      { role: role.toUpperCase(), user, event, invitationAnswer: answer.toUpperCase() })
      .pipe(map(v => plainToClass(UserEvent, v.data as UserEvent)));
  }

  getUserEvent(id: number): Observable<UserEvent> {
    return this.http.get<Response>(`${Environment.urls.API}/core/userEvent/${id}/`)
      .pipe(map(v => plainToClass(UserEvent, v.data as UserEvent)));
  }

  putUserEvent(id: number, event: number, user: number, role: UserEventRole, answer: UserEventAnswer): Observable<UserEvent> {
    return this.http.put<Response>(`${Environment.urls.API}/core/userEvent/${id}/`,
      { role: role.toUpperCase(), user, event, invitationAnswer: answer.toUpperCase(), invitationAnsweredAt: new Date() })
      .pipe(map(v => plainToClass(UserEvent, v.data as UserEvent)));
  }

  deleteUserEvent(id: number): Observable<Response> {
    return this.http.delete<Response>(`${Environment.urls.API}/core/userEvent/${id}/`);
  }

  setUserAsAcceptedChair(event: number, user: number): Observable<UserEvent> {
    return this.setUserEvent(event, user, UserEventRole.CHAIR, UserEventAnswer.ACCEPTED);
  }

  sendInvitationEmail(event_id: number, users_ids: Array<number>): Observable<{ [user: number]: boolean }> {
    return this.http.post<Response>(`${Environment.urls.API}/core/userEvent/sendInvitationEmail/`, { event_id, users_ids })
      .pipe(map(v => v.data));
  }

  sendTpcMemberNotificationEmail(event_id: number, users_ids: Array<number>): Observable<{ [user: number]: boolean }> {
    return this.http.post<Response>(`${Environment.urls.API}/core/userEvent/sendTpcMemberNotificationEmail/`, { event_id, users_ids })
      .pipe(map(v => v.data));
  }

  sendChairNotificationEmail(event_id: number, users_ids: Array<number>): Observable<{ [user: number]: boolean }> {
    return this.http.post<Response>(`${Environment.urls.API}/core/userEvent/sendChairNotificationEmail/`, { event_id, users_ids })
      .pipe(map(v => v.data));
  }

  inheritTPCList(fromEvent: number, toEvent: number, params): Observable<Array<UserEvent>> {
    return this.http.post<Response>(`${Environment.urls.API}/core/userEvent/inheritTPC/fromEvent/${fromEvent}/toEvent/${toEvent}/`, params)
      .pipe(map(v => plainToClass(UserEvent, v.data as Array<UserEvent>)));
  }

  createEventNotificationEmail(event: number, params): Observable<NotificationEmail> {
    return this.http.post<Response>(`${Environment.urls.API}/core/notificationEmail/${event}/`, params)
      .pipe(map(v => plainToClass(NotificationEmail, v.data as NotificationEmail)));
  }
  editEventNotificationEmail(event: number, trigger: EmailTrigger, params): Observable<NotificationEmail> {
    return this.http.put<Response>(`${Environment.urls.API}/core/notificationEmail/byEvent/${trigger}/${event}/`, params)
      .pipe(map(v => plainToClass(NotificationEmail, v.data as NotificationEmail)));
  }

  editDefaultEmailTemplate(trigger: EmailTrigger, params): Observable<NotificationEmail> {
    return this.http.put<Response>(`${Environment.urls.API}/core/notificationEmail/byTrigger/${trigger}/`, params)
      .pipe(map(v => plainToClass(NotificationEmail, v.data as NotificationEmail)));
  }

  getEventNotificationEmail(trigger: EmailTrigger): Observable<NotificationEmail> {
    return this.http.get<Response>(`${Environment.urls.API}/core/notificationEmail/byTrigger/${trigger}/`)
      .pipe(map(v => plainToClass(NotificationEmail, v.data as NotificationEmail)));
  }

  getEventNotificationEmailbyEvent(trigger: EmailTrigger, event: number): Observable<NotificationEmail> {
    return this.http.get<Response>(`${Environment.urls.API}/core/notificationEmail/byEvent/${trigger}/${event}/`)
      .pipe(map(v => plainToClass(NotificationEmail, v.data as NotificationEmail)));
  }

  confirmInvitation(id: number, answer: UserEventAnswer): Observable<UserEvent> {
    return this.http.put<Response>(`${Environment.urls.API}/core/userEvent/${id}/`,
      { invitationAnswer: answer.toUpperCase(), invitationAnsweredAt: new Date() })
      .pipe(map(v => plainToClass(UserEvent, v.data as UserEvent)));
  }

  sendPersonalizedEmail(eventId: number, params): Observable<NotificationEmail[]> {
    return this.http.post<Response>(`${Environment.urls.API}/core/event/${eventId}/sendEmails/`, params)
      .pipe(map(v => plainToClass(NotificationEmail, v.data as NotificationEmail[])));
  }

  showPreviewPersonalizedEmail(eventId: number, params): Observable<NotificationEmail[]> {
    return this.http.post<Response>(`${Environment.urls.API}/core/event/${eventId}/showEmails/`, params)
      .pipe(map(v => plainToClass(NotificationEmail, v.data as NotificationEmail[])));
  }

  getMimetypes(): Observable<Array<FileType>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/mimetype/`)
      .pipe(map(v => plainToClass(FileType, v.data as Array<FileType>)));
  }

  getUserEventTopicsInterest(id: number): Observable<UserEventTopicsInterest> {
    return this.http.get<Response>(`${Environment.urls.API}/core/userEventTopicsInterest/${id}/`)
      .pipe(map(v => plainToClass(UserEventTopicsInterest, v.data as UserEventTopicsInterest)));
  }

  getUserEventTopicsInterestByUserInEvent(eventId: number, userId: number): Observable<UserEventTopicsInterest[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/userEventTopicsInterest/${eventId}/byUser/${userId}/`)
      .pipe(map(v => plainToClass(UserEventTopicsInterest, v.data as UserEventTopicsInterest[])));
  }

  getUserEventTopicsInterestByEvent(eventId: number): Observable<UserEventTopicsInterest[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/userEventTopicsInterest/byEvent/${eventId}/`)
      .pipe(map(v => plainToClass(UserEventTopicsInterest, v.data as UserEventTopicsInterest[])));
  }

  getUserEventTopicsInterestByUser(userId: number): Observable<UserEventTopicsInterest[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/userEventTopicsInterest/byUser/${userId}/`)
      .pipe(map(v => plainToClass(UserEventTopicsInterest, v.data as UserEventTopicsInterest[])));
  }

  getSubmissionFieldsAndFilters(eventId: number, page: string): Observable<SubmissionFieldAndFilter> {
    return this.http.get<Response>(Environment.urls.API + '/core/event/' + eventId + '/SubmissionFieldsAndFilters/', { params: { page } })
      .pipe(map(v => v.data as SubmissionFieldAndFilter));
  }

  putSubmissionFieldsAndFilters(eventId:number, params): Observable<SubmissionFieldAndFilter> {
    return this.http.put<Response>(Environment.urls.API + '/core/event/' + eventId + '/SubmissionFieldsAndFilters/', params)
      .pipe(map(v => v.data as SubmissionFieldAndFilter));
  }

  setUserEventTopicsInterest(user: number, event: number, topics) {
    return this.http.put<Response>(`${Environment.urls.API}/core/userEventTopicsInterest/${event}/byUser/${user}/`, topics)
      .pipe(map(v => plainToClass(UserEventTopicsInterest, v.data as UserEventTopicsInterest)));
  }

  public getEventAndTrackByTrackID(id: number): Observable<{ event: Event, track: Track }> {
    return forkJoin([
      this.getEventByTrack(id),
      this.getTrack(id)
    ]).pipe(map(result => {
      return {
        event: result[0],
        track: result[1]
      };
    }));
  }

  public getMissingManuscripts(id: number): Observable<Array<Submission>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${id}/missingManuscripts/`)
      .pipe(map(v => plainToClass(Submission, v.data as Submission[])));
  }

  public notifyAuthorsWithMissingManuscripts(id: number): Observable<Response> {
    return this.http.post<Response>(`${Environment.urls.API}/core/event/${id}/missingManuscripts/notify/`, { id });
  }

  public eventURIValidator(eventURI?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (control.value ?.length > 0 && control.value !== eventURI) {
        return this.getUsedEventURI(control.value).pipe(
          map(isTaken => (isTaken ? { usedURI: true } : null)),
          catchError(() => of(null))
        );
      }
      return of(null);
    };
  }

  directCanUserEditSubForm(event: number): Observable<boolean> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${event}/editSubForm/`).pipe(map(bool => <boolean>bool.data)
    );
  }

  directCanUserEditReviewForm(event: number): Observable<boolean> {
    return this.http.get<Response>(`${Environment.urls.API}/core/event/${event}/editReviewForm/`).pipe(map(bool => <boolean>bool.data)
    );
  }

  public userEventListToUserEvent(data: any){
    return data.map(item => {
      let affiliation =  new Affiliation(item.affiliationName); // Set Affiliation
      affiliation._acronym = item.affiliationAcronym;
      let profile = new Profile();  // Set Profile
      profile.affiliation = affiliation;
      profile.country = item.country;
      // Set User
      let user =  plainToClass(User, item, { excludeExtraneousValues: true});
      user.id = item.user;
      user.isActive = item.isActive;
      user.profile = profile;

      //Set UserEvent
      let userEvent = plainToClass(UserEvent, item, { excludeExtraneousValues: true});
      userEvent.id = item.id;
      userEvent.topicsInterest = item.topicsInterest;
      userEvent.claims = item.bid;
      userEvent.user = user;
      userEvent.tpcGroup = item.tpcGroup;
      return userEvent;
    });
  }
}
