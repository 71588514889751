import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import * as dayjs from 'dayjs';
import { Title } from '@angular/platform-browser';
import { Event } from '../../model/event';
import { EventsService } from '../../service/events.service';
import { EventInfo } from '../../model/eventInfo';
import { NavbarService } from 'src/app/service/navbar.service';
import { InfoService } from 'src/app/service/info.service';
import { AuthService } from 'src/app/service/auth.service';
import { UserEventAnswer } from '../../enum/user.event.answer';
import { LanguageService } from '../../../app/service/language.service';
import { TimezoneService } from 'src/app/service/timezone.service';
import { User } from 'src/app/model/user';
import { UserEventRole } from '../../enum/user.event.role';
import { AdminService } from 'src/app/service/admin.service';
import { EventUserViewComponent } from '../event-user-view/event-user-view.component';


@Component({
  selector: 'app-event-view',
  templateUrl: './event-view.component.html',
  styleUrls: ['./event-view.component.scss']
})
export class EventViewComponent implements OnInit {
  @ViewChild(EventUserViewComponent) eventUserView!: EventUserViewComponent;

  public event: Event;
  public eventChairs: Array<User>
  public info: EventInfo;
  public logo = './assets/images/jems.svg';

  public userIsCommiteeMember = false;
  public userHasPendingInvite = false;
  public showEventAdminButton = false;

  public userIsChair = false;

  public timeZoneEvent: string;
  public timeZoneEventOffset: string;
  public loadingSubmissions: boolean = false;

  constructor(
    public adminService: AdminService,
    public router: Router, public route: ActivatedRoute,
    public eventService: EventsService, public location: Location,
    public navbarService: NavbarService, public infoService: InfoService,
    public authService: AuthService,
    public languageService: LanguageService,
    public TimezoneService: TimezoneService,
    private titleService: Title
  ) { }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.location.back();
  }

  ngOnInit() {
    setTimeout(() => {
      this.navbarService.loadingStart();
      this.route.params.subscribe(params => {
        const id: number = parseInt(params.id, 10);
        this.eventService.getEvent(id)
        .subscribe(e => {
          this.adminService.selectedEvent = e
          this.event = e;
          this.infoService.selectedEvent = this.event;
          if (this.event.eventInfo.logo && this.event.eventInfo.logo.length > 0) {
            this.logo = this.event.eventInfo.logo;
          }
          this.getEventChairs();
          this.updateHeadTitle();
          this.checkIfEventCommittee();
          this.checkIfEventChairOrAdmin();
          if(this.event.subconferences){
            this.sortEvents(this.event.subconferences)
          }
          this.navbarService.loadingStop();
          
          // After loading new event, make sure that the event-user-view app is loaded again
          this.reloadSubmissions();

        });
      });
    });
  }

  public getEventChairs(): void {
    this.eventService.getEventMinimalPeopleByRole(this.event.id, UserEventRole.CHAIR).subscribe(chairs => {
      this.eventChairs = chairs.map(chair => chair.user);
    });
  }

  reloadSubmissions(){
    this.loadingSubmissions = true;
    setTimeout(() => {
      this.loadingSubmissions = false;
    });

  }
  updateHeadTitle(){
    let eventAcronymYear = this.event.acronym + " " + this.event.year + " - ";
    let oldTitle = this.titleService.getTitle();
    if (!oldTitle.includes(eventAcronymYear)){
      this.titleService.setTitle(eventAcronymYear + this.titleService.getTitle());
    }
  }

  get baseUrl(): string {
    return window.location.origin;
  }

  checkIfEventCommittee() {
    const userEvent = this.event.userEvent;
    if (userEvent && userEvent.user !== null) {
      this.userIsCommiteeMember = !!userEvent && (userEvent?.invitationAnswer.toUpperCase() === UserEventAnswer.ACCEPTED.toUpperCase());
      this.userHasPendingInvite = !!userEvent && (userEvent?.invitationAnswer.toUpperCase() === UserEventAnswer.NOT_ANSWERED.toUpperCase())
                                              && (!!userEvent?.invitationSentAt);
    }
  }

  checkIfEventChairOrAdmin(): void {
    this.authService.userIsAdminOrChair(this.event.id).subscribe(isAdminOrChair => {
      this.showEventAdminButton = isAdminOrChair;
      this.userIsChair = isAdminOrChair;
    });
  }

  today() {
    return dayjs(new Date());
  }

  public isEventPublic(eventPublicAt): boolean {
    return this.today().isAfter(eventPublicAt);
  }

  public sortEvents(events: Event[]): void{
    events.sort((a,b) => this.formatName(a) < this.formatName(b) ? -1 : this.formatName(a) > this.formatName(b) ? 1 : 0);
  }

  public formatName(event: Event): string{
    return `${event.year ? event.acronym + " " +  event.year.toString() : event.acronym} - ${event.name}`
  }
}
