import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../../../../service/admin.service';
import { Event } from '../../../../model/event';
import { UserEventRole } from '../../../../enum/user.event.role';
import { UserEvent } from '../../../../model/user.event';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { User } from 'src/app/model/user';
import { UserService } from 'src/app/service/user.service';
import { EventsService } from 'src/app/service/events.service';
import { NotificationService } from 'src/app/service/notification.service';
import * as dayjs from 'dayjs';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { ExportService } from 'src/app/service/export.service';
import { CountryPipe } from 'src/app/pipe/country.pipe';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-event-chairs',
  templateUrl: './event-chairs.component.html',
  styleUrls: ['./event-chairs.component.scss']
})
export class EventChairsComponent implements OnInit {
  event: Event;
  chairs: Array<UserEvent>;
  chairTableColumns = ['position', 'name', 'email', 'affiliation', 'country', 'notification-date', 'send-notification'];
  users: Array<User>;
  form: FormGroup;

  searchFoundUsers: Array<User>;
  userSignupForm: FormGroup;

  sort: MatSort;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.sort = sort;

    if (this.sort) {
      this.dataSource.sort = this.sort;
      this.dataSource.sortingDataAccessor = (item: UserEvent, header: string) => {
        switch (header) {
          case 'name': return item.user.name;
          case 'email': return item.user.email;
          case 'affiliation': return item.user.profile.affiliation.name;
          case 'country': return item.user.profile.country;
          case 'notification-date': return item.notificationSentAt;
          default: return item[header];
        }
      };
    }
  }

  dataSource: MatTableDataSource<UserEvent>;

  // The use of this list is for disabling and enabling the actions on the table's menu
  selectedList: UserEvent[] = [];

  constructor(
    private adminService: AdminService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private userService: UserService,
    private eventsService: EventsService,
    public exportService: ExportService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.getChairs();
        });
      } else {
        this.getChairs();
      }
    });
  }

  public getChairs(): void {
    this.adminService.eventsService.getUserEventListByRole(this.event.id, UserEventRole.CHAIR).subscribe(chairs => {
      this.setChairs(chairs);

      this.initForm();
    });
  }

  public initForm(): void {
    this.form = this.fb.group({
      chairs: [this.chairs.map(chair => chair.user)]
    });

    this.form.get('chairs').valueChanges.subscribe((users: Array<User>) => {
      users.forEach(user => {
        if (!this.chairs.find(u => u.id === user.id)) {
          this.setUserAsChair(this.event, user);

          // Remove usuário da lista de pesquisa de usuários.
          return users.splice(0, 1);
        }
      });
    });
  }

  public removeChair(): void {
    this.adminService.progressBar.start();

    const IDchairsMarked = this.chairs.filter(chair => chair.sendInvite).map(chair => chair.id);

    const params = { params: { length: IDchairsMarked.length } };

    forkJoin(
      IDchairsMarked.map((id: number) => this.eventsService.deleteUserEvent(id))
    ).subscribe(() => {
      next: this.setChairs(this.chairs.filter(chair => !chair.sendInvite));
      complete: {
        this.adminService.progressBar.stop();

        if (IDchairsMarked.length > 1) {
          this.notificationService.notify('admin.event.people.tpc.removed-users-as-chair', params);
          return;
        }

        this.notificationService.notify('admin.event.people.tpc.removed-user-as-chair', params);
      }
    });
  }

  private setChairs(chairs: Array<UserEvent>): void {
    this.chairs = chairs;
    this.dataSource = new MatTableDataSource<UserEvent>(this.chairs);
    this.users = chairs.map(c => c.user);
  }

  private pushChair(chair: UserEvent): void {
    this.chairs = this.chairs.concat([chair]);
    this.dataSource.data = this.chairs;
    this.users = this.chairs.map(c => c.user);
  }

  setUserAsChair(event: Event, user: User): void {
    this.adminService.progressBar.start();
    this.notificationService.notify('admin.event.people.tpc.setting-user-as-chair', { params: { user: user.name } });
    this.adminService.eventsService.setUserAsAcceptedChair(event.id, user.id).subscribe(
      newChair => {
        this.adminService.progressBar.stop();
        this.pushChair(newChair);
        this.notificationService.notify('admin.event.people.tpc.set-user-as-chair', { params: { user: user.name } });
      },
      error => {
        this.adminService.progressBar.stop();
      }
    );
  }

  sendNotifications(): void {
    const usersNotificated = this.chairs.filter(e => e.sendInvite);
    const usersID = usersNotificated.map(e => e.user.id);

    if (usersID && usersID.length > 0) {
      this.adminService.progressBar.start();
      this.notificationService.notify('admin.event.people.tpc.sending-notification-added-tpc-chair');

      this.eventsService.sendChairNotificationEmail(this.event.id, usersID).subscribe(() => {
        usersNotificated.forEach(user => {
          user.notificationSentAt = dayjs();
        });

        this.adminService.progressBar.stop();
        this.notificationService.notify('admin.event.people.tpc.notification-sent');
      });
    }

    this.resetButtonChair();
  }

  resendActivationEmail(user: User): void {
    this.notificationService.notify('admin.event.people.tpc.activation-email.sending', { params: { user: user.name } });
    this.userService.resendActivationEmail(user.email).subscribe(
      response => {
        this.notificationService.notify('admin.event.people.tpc.activation-email.sent', { params: { user: user.name } });
        this.adminService.progressBar.stop();
      },
      error => {
        if (error.status === 304) {
          this.notificationService.notify('errors.user-already-active');
        }
        this.adminService.progressBar.stop();
      }
    );
  }

  private resetButtonChair(): void {
    this.chairs.forEach(chair => chair.sendInvite = false);
  }

  private selectAllChairs() {
    this.chairs.forEach(user => user.sendInvite = true);
  }

  private deselectAllChairs() {
    this.chairs.forEach(user => user.sendInvite = false);
  }

  private setSelectedList(): void{
    this.selectedList = this.chairs.filter(e => e.sendInvite);
  }

  public get atLeastOneSelection() : Boolean {
    return this.selectedList.length > 0;
  }

  public disabledTooltip(){
    if (!this.atLeastOneSelection) {
      return this.translateService.instant('forms.buttons.validation.selectPeople');
    }
    return "";
  }

  public exportChairToText() {
    const chairsEmail = this.chairs.map(m => `\"${m.user.name}\" <${m.user.email}>`).join('\n');
    this.exportService.downloadFile(`${this.event.name}-chairs.txt`, chairsEmail, 'text/plain');
  }

  public exportChairToCSV() {
    const countryPipe = new CountryPipe(this.translateService);
    const chairsCSV = this.chairs.map(m => `${m.user.name};${m.user.profile.affiliation.name};${countryPipe.transform(m.user.profile.country)};${m.user.email}`).join('\n');
    this.exportService.downloadFile(`${this.event.name}-chairs.csv`, chairsCSV, 'text/csv');
  }
}
