import { Component, Output, EventEmitter, Input } from '@angular/core';
import { EventsService } from 'src/app/service/events.service';
import { NotificationService } from 'src/app/service/notification.service';
import { Event } from 'src/app/model/event';
import { SubmissionStatus } from 'src/app/enum/submission.status';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { forkJoin } from 'rxjs';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { SubmissionRanking } from '../submissions-list-table-v2/submissions-list-table-v2.component';
import { SubmissionWithdrawalComponent } from '../submission-withdrawal/submission-withdrawal.component';
import { SubmissionDeleteComponent } from '../submission-delete/submission-delete.component';
import { Submission } from 'src/app/model/paper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu-submission-list',
  templateUrl: './menu-submission-list.component.html',
  styleUrls: ['./menu-submission-list.component.scss']
})
export class MenuSubmissionListComponent {
  public SubmissionStatus = SubmissionStatus;

  @Input() public event: Event;
  @Input() public submissions: Array<SubmissionRanking>;

  @Output() public changedStatusSubmissions: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public submissionsDeleted: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() public submissionsSelect: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private dialog: MatDialog,
    private submissionsService: SubmissionsService,
    private translate: TranslateService,
  ) { }

  public changeStatusSubmissions($status: string): void {
    switch ($status) {
      case SubmissionStatus.ACTIVE:
        this.openDialog(SubmissionStatus.ACTIVE);
        break;
      case SubmissionStatus.ACCEPTED:
        this.openDialog(SubmissionStatus.ACCEPTED);
        break;
      case SubmissionStatus.REJECTED:
        this.openDialog(SubmissionStatus.REJECTED);
        break;
      case SubmissionStatus.PENDING:
        this.openDialog(SubmissionStatus.PENDING);
        break;
      case SubmissionStatus.WITHDRAWN:
        this.openWithdrawnDeleteDialog(SubmissionStatus.WITHDRAWN);
        break;
      case SubmissionStatus.DELETED:
        this.openWithdrawnDeleteDialog(SubmissionStatus.DELETED);
        break;
      default:
        break;
    }
  }

  public openDialog(status: SubmissionStatus): void {
    const data = {
      title: `submissions.ranking.select-submission-${status}`,
      content: ''
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation) {
        this.submitStatusSubmissions(status);
      }
    });

  }

  private submitStatusSubmissions(status: SubmissionStatus): void {
    forkJoin(
      this.submissionsSelected.map(s => {
        s.submission.status = status;
        return this.submissionsService.editSubmission({ status }, s.submission.id);
    }))
      .subscribe(() => {
        this.notify(status);
        this.setStatusSubmissions(status);
      });
  }

  private setStatusSubmissions(status: SubmissionStatus): void {
    this.submissionsSelected.forEach(({ submission }) => submission.status = status);
    this.changedStatusSubmissions.emit(true);
  }

  private openWithdrawnDeleteDialog(status: SubmissionStatus): void {
    const dialogConfig = new MatDialogConfig();

    const submissions = this.submissionsSelected.map(s => s.submission);

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = { submission: null , submissions };

    let dialogRef: MatDialogRef<SubmissionWithdrawalComponent | SubmissionDeleteComponent, MatDialogConfig>;

    if (status === SubmissionStatus.WITHDRAWN) {
      dialogRef = this.dialog.open(SubmissionWithdrawalComponent, dialogConfig);
    } else {
      dialogRef = this.dialog.open(SubmissionDeleteComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((response) => {
      if (response && status === SubmissionStatus.WITHDRAWN) {
        this.setStatusSubmissions(SubmissionStatus.WITHDRAWN);
        return;
      }

      this.submissionsDeleted.emit((response as Submission[]).map(submission => submission.id));
    });
  }

  public changeRoute(): void {
    const submissionsIDPending: Array<number> = this.submissionsIDPending;

    const ids = JSON.stringify(submissionsIDPending);

    this.router.navigate([`/admin/${this.event.id}/submissions/notify-pending`], { queryParams: { ids: ids, eventID: this.event.id }});
  }

  private get submissionsIDPending(): Array<number> {
    const submissionsPending: Array<SubmissionRanking> = this.submissions.filter((s: SubmissionRanking) => {
      if (s.selected && s.submission.status === SubmissionStatus.PENDING) {
        return s.submission.id;
      }
    });

    return submissionsPending.map((s: SubmissionRanking) => s.submission.id);
  }

  private get submissionsSelected(): SubmissionRanking[] {
    return this.submissions.filter((s: SubmissionRanking) => s.selected);
  }

  private notify(status: SubmissionStatus): void {
    const message = `reports.submissions.ranking.successful-assign-${status}`;

    this.notificationService.notify(message, { params: { total: this.submissionsSelected.length } });
  }

  public get AtLeastOneSelection() : Boolean {
    return this.submissionsSelected.length > 0;
  }

  public disabledTooltip(){
    if (!this.AtLeastOneSelection) {
      return this.translate.instant('forms.buttons.validation.selectAtLeastOne');
    }

    return "";
  }
  
  public selectAllLines(): void{
    this.submissions.map(submission =>{
      submission.selected = true;
    })
    this.submissionsSelect.emit(true);
  }
  public deselectAllLines(): void{
    this.submissions.map(submission =>{
      submission.selected = false;
    })
    this.submissionsSelect.emit(false);
  }
}
