import { Component, Input, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { Event } from '../../model/event';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})

export class EventComponent implements OnInit{
  @Input() event: Event;
  @Input() showEventAdminButton = false;
  userIsChair: boolean = false;

  constructor(
      public authService: AuthService
    ) {}

  ngOnInit(): void {
    this.checkIfEventChairOrAdmin();
    if(this.event.subEvents){
      this.sortEvents(this.event.subEvents);
    }
  }

  today() {
    return dayjs(new Date());
  }

  get baseUrl(): string {
    return window.location.origin;
  }

  public isEventPublic(event: Event): boolean {
    return this.today().isAfter(event.eventInfo.makePublicAt);
  }
  private checkIfEventChairOrAdmin(): void {
    this.authService.userIsAdminOrChair(this.event.id).subscribe(isAdminOrChair => {
      this.userIsChair = isAdminOrChair;
    });
  }
  public sortEvents(events: Event[]): void{
    events.sort((a,b) => a.formattedName < b.formattedName ? -1 : a.formattedName > b.formattedName ? 1 : 0);
  }
}
