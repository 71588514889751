import { Component, ViewChild, OnInit } from '@angular/core';
import { EmailTrigger } from '../../../../enum/email.trigger';
import { TranslateService } from '@ngx-translate/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AdminService } from '../../../../service/admin.service';
import { Event } from '../../../../model/event';
import { EventsService } from '../../../../service/events.service';
import { NotificationService } from '../../../../service/notification.service';
import { UserSearchComponent } from '../../../../component/user-search/user-search.component';
import { User } from '../../../../model/user';
import { ActivatedRoute } from '@angular/router';
import { EmailTemplatesEditDirective } from 'src/app/class/email-templates-edit';
import { SelectOption } from '../../../../model/select.option';
import { UserNotification } from '../../../../model/user.notification';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserSearchDialogComponent } from '../../../../component/user-search-dialog/user-search-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/component/confirmation-dialog/confirmation-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { plainToClass } from 'class-transformer';

export class TriggerData {
  id: number;
  name: string;
  trigger: string;
}

@Component({
  selector: 'app-event-settings-email',
  templateUrl: './event-settings-email.component.html',
  styleUrls: ['./event-settings-email.component.scss']
})
export class EventSettingsEmailComponent extends EmailTemplatesEditDirective implements OnInit {
  event: Event;
  @ViewChild('search') search: UserSearchComponent;

  paramTemplate: string;
  emailExists = false;

  emailTriggers: Array<SelectOption>;
  dataSource: MatTableDataSource<TriggerData>;
  triggerDataList: Array<TriggerData>;
  users: Array<UserNotification>;
  userTriggerControlForm: FormGroup;

  columns = ['trigger'];

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.sort = sort;
    if (this.sort) {
      this.dataSource.sort = this.sort;

      this.dataSource.sortingDataAccessor = (item: TriggerData, header: string) => {
        switch (header) {
          case 'trigger': return item.name;
          case 'user': return item.name;
          default: return item[header]; // Default is status columns
        }
      };
    }
  }
  sort: MatSort;

  constructor(
    protected fb: FormBuilder,
    protected translateService: TranslateService,
    protected eventsService: EventsService,
    private adminService: AdminService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    super(true, fb, translateService, eventsService);
  }

  ngOnInit() {
    setTimeout(() => {
      this.adminService.progressBar.start();      
      this.route.queryParams.subscribe(({ template }) => {
        if (template) {
          this.paramTemplate = template;
        }
      });

      this.event = this.adminService.selectedEvent;
      if (!this.event) {
        this.adminService.getEvent().subscribe(event => {
          this.event = event;
          this.initComponent();
        });
      } else {
        this.initComponent();
      }
    });
  }

  initComponent(): void {
    this.userForm = this.fb.group({
      user: ['', [Validators.required]],
    });

    this.initForm();
    this.getEventNotifications();
    this.parameterTemplate();
  }

  private parameterTemplate(): void {
    setTimeout(() => {
      // If parameter template is defined, test if valid.
      // In which case, set emailTrigger value and execute email selection change method.
      if (this.paramTemplate) {
        const validParamTemplate = this.emailTriggers.find(trigger => trigger.id === this.paramTemplate);

        if (validParamTemplate) {
          const emailTriggerControl = this.filterForm.controls['emailTrigger'];

          emailTriggerControl.setValue(this.paramTemplate);
          this.getEmailTemplate(emailTriggerControl.value);
        }
      }
    });
  }

  selectTrigger(trigger: EmailTrigger): void { 
    // Scroll down so user can see the edit options 
    // (get the toolbar at the top to calculate the offset, so that the title of the editing page appears at the top)
    setTimeout(() => {
      const editingTitle = document.getElementsByClassName("sub-title-edit")[0];
      const toolbarElement = document.getElementsByClassName('mat-toolbar')[0] as HTMLElement;
      const toolbarHeight = toolbarElement ? toolbarElement.offsetHeight : 0;
      // Calculate the target div's position relative to the document
      const elementPosition = editingTitle.getBoundingClientRect().top + window.scrollY;      
      window.scrollTo({
        top: elementPosition - toolbarHeight,
        behavior: 'smooth'
      });
    },0)

    this.filterForm.get('emailTrigger')?.setValue(trigger);
    this.getEmailTemplate(trigger);
  }

  getSelectedTriggerLabel(): string {
    if (this.filterForm.get('emailTrigger').value != null && this.triggerDataList.length > 0) {
      return this.triggerDataList.filter(t => t.trigger == this.filterForm.get('emailTrigger').value)[0]?.name;
    }
    return null;
  }

  getEmailTemplate(trigger: EmailTrigger, onlyUpdateTo: boolean=false): void {    
    this.eventsService.getEventNotificationEmailbyEvent(trigger, this.event.id)
      .subscribe(nf => {
        if (onlyUpdateTo) {
          this.notificationEmail.to = nf.to;
        } else {
          this.changeType = true;
          this.notificationEmail = nf;
          this.emailExists = true;
          this.filterForm.patchValue({
            emailSubject: nf.subject,
            emailTextForm: nf.content
          });
        }
      }, () => {
        this.getDefaultEmailTemplate(trigger);
      });
  }

  updateSelectedEmailTemplate(): void {
    if (this.emailExists) {
      this.eventsService.editEventNotificationEmail(this.event.id, this.notificationEmail.trigger, this.notificationEmail)
        .subscribe(nf => {
          this.notificationService.notify('admin.email-template.email-updated');
          this.notificationEmail = nf;
        });
    } else {
      this.eventsService.createEventNotificationEmail(this.event.id, this.notificationEmail)
        .subscribe(nf => {
          this.notificationEmail = nf;
          this.emailExists = true;
        });
    }
  }

  addUser(): void {
    if (this.notificationEmail.to) {
      this.notificationEmail.to = this.notificationEmail.to.map((user: User) => user.id);
    }
    this.notificationEmail.to.push(this.search.formGroupController.get(this.search.name).value[0].id);
    this.updateSelectedEmailTemplate();
    this.getEventNotifications();
    this.search.formGroupController.get(this.search.name).setValue([]);
    this.search.clearNativeInput();
  }

  deleteUser(user: User): void {
    let trigger_id = this.triggerDataList.filter( t => 
      t.trigger == this.filterForm.get('emailTrigger').value
    )[0]?.id;

    let userN = this.users.filter(u => u.id == user.id)[0];
    if (userN instanceof UserNotification && trigger_id) {
      this.userTriggerControlForm.get(`${userN.id}` + '-' + trigger_id)?.setValue(false);
      this.updateUserTrigger(userN, trigger_id, false);
    }
  }


  // TABLE LIST
  getEventNotifications() {
    this.adminService.progressBar.start();
    this.eventsService.getEventCoChairsNotification(this.event.id).subscribe(data => {
      this.userTriggerControlForm = this.fb.group({});

      this.triggerDataList = data["triggers"].filter(t =>
        EmailTrigger.toIterable().includes(t["trigger"])
      ).map(trigger => ({
            id: trigger["id"],
            name: EmailTrigger.getName(trigger["trigger"]),
            trigger: trigger["trigger"]
      }));
      this.dataSource = new MatTableDataSource<TriggerData>(this.triggerDataList);
      this.users = data["users"].map(user => plainToClass(UserNotification, user));

      this.triggerDataList.forEach(trigger => {
        this.users.forEach(user => {
          const controlName = `${user.id}-${trigger.id}`;
          const checkListValue = user.includeTrigger(trigger.id);
          this.userTriggerControlForm.addControl(controlName, new FormControl(checkListValue));
        })
      });

      this.users.forEach(user => {
        const controlName = `${user.id}`;
        const value = this.getUsertriggerTrueCount(user) == this.triggerDataList.length;
        this.userTriggerControlForm.addControl(controlName, new FormControl(value));
        this.setUserMarkAllTreiggers(user);
      });
      this.columns = ['trigger'];
      this.columns = this.columns.concat([...this.users.map(u => 'user_' + u.id.toString()), 'addUser']);      
    });
    this.adminService.progressBar.stop();
  }

  setUserMarkAllTreiggers(user: UserNotification): void {
    this.userTriggerControlForm.get(`${user.id}`)?.setValue(this.getUsertriggerTrueCount(user) == this.triggerDataList.length);
  }

  getCheckIcon(user: UserNotification): string {
    if (this.getUsertriggerTrueCount(user) == 0) {
      return 'fa-square';
    } else {
       return this.getUsertriggerTrueCount(user) == this.triggerDataList.length? 'fa-check-square' : 'fa-minus-square';
    }
  }

  getUsertriggerTrueCount(user: UserNotification): number {
    return this.triggerDataList.filter(t => this.userTriggerControlForm.get(user.id.toString() + '-' + t.id).value).length;
  }

  addSearchUser(trigger: TriggerData): void {
    const dialogConfig = new MatDialogConfig();
    
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45rem';
    dialogConfig.height = '23rem';
    dialogConfig.panelClass = 'my-custom-dialog-class';
    
    const dialogRef = this.dialog.open(UserSearchDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((user: UserNotification) => {
      if (user) {        
        this.updateUserTrigger(user, trigger.id, true, true);
      }
    });
  }

  updateUserTrigger(user: UserNotification, trigger_id: number, valueUpdate: boolean, updateTable:boolean=false): void {     
    let value = trigger_id ? valueUpdate : !this.userTriggerControlForm.get(user.id.toString()).value;
    if (!value && !user.chair && (this.getUsertriggerTrueCount(user) == 0 || !trigger_id)) {      
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'admin.event.cochairs-notification.dialog-title-remove-user',
          content: ''
        }
      }).afterClosed().subscribe(confirmed => {
        if (confirmed) {
          this.updateTrigger(user,trigger_id,value);
          let index = this.columns.findIndex(c => c === 'user_'+user.id.toString());
          this.columns.splice(index, 1);
        } else {
          this.userTriggerControlForm.get(`${user.id}` + '-' + trigger_id)?.setValue(true);
        }
      });
    } else {
      this.updateTrigger(user,trigger_id,value,updateTable);
    }
  }  

  updateTrigger(user: UserNotification, trigger_id: number, value: boolean, updateTable:boolean=false): void {
    let triggers = [];
    if (trigger_id) {
      triggers.push({'id': trigger_id, 'value': value});
    } else {
      this.triggerDataList.forEach(trigger => {
        this.userTriggerControlForm.get(`${user.id}-${trigger.id}`).setValue(value);
        triggers.push({'id': trigger.id, 'value': value});
      });
    }

    let params = {
      'user': {
        'id': user.id,
        'triggers': triggers
      }
    };
    this.eventsService.editEventCoChairsNotification(this.event.id, params).subscribe(
      () => {},
      () => {},
      () => { 
        if (updateTable) {
          this.getEventNotifications();
        } else {
          this.setUserMarkAllTreiggers(user);
        }
        this.updateEditTemplate();
    });
  }

  updateEditTemplate(): void {    
    if (this.filterForm.get('emailTrigger').value != null) {
      this.getEmailTemplate(this.filterForm.get('emailTrigger').value, true);
    }
  }
}
