import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Submission } from '../../model/paper';
import { EventsService } from 'src/app/service/events.service';
import { PreferencesService } from '../../service/preferences.service';
import { Review } from '../../model/paper';
import { SubmissionStatus } from '../../enum/submission.status';
import { ReviewStatus } from '../../enum/review.status';
import { EventReviewConfiguration } from 'src/app/model/eventReviewConfiguration';
import { UserService } from 'src/app/service/user.service';
import { InfoService } from 'src/app/service/info.service';
import { User } from 'src/app/model/user';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';
import { SubmissionsService } from 'src/app/service/submissions.service';
import { FileUploadViewDirective } from 'src/app/class/file-upload-view';
import { FilesService } from 'src/app/service/files.service';
import { Track } from 'src/app/model/track';
import { FileRules } from 'src/app/model/file.rules';

export const DEFAULT_PAPER_FIELDS_SHOW = ['id', 'title', 'status', 'topics', 'authors', 'authors-names', 'authors-emails', 'authors-affiliations', 'submission-event', 'submission-track', 'rebuttal', 'discussion-messages', 'others-files', 'score-average'];

@Component({
  selector: 'app-paper-card',
  templateUrl: './paper-card.component.html',
  styleUrls: ['./paper-card.component.scss']
})
export class PaperCardComponent extends FileUploadViewDirective implements OnInit {
  @Input() element: Submission | Review;
  @Input() fieldsShow: Array<string> = [...DEFAULT_PAPER_FIELDS_SHOW];
  @Input() isEventInterface = false;
  @Input() isSearchResult = false;
  @Input() userIsChair = false;
  @Input() reviewConfig: EventReviewConfiguration;
  
  @Output() submissionChanged = new EventEmitter<Submission>();

  public submission: Submission;
  public status: any;
  public statusClass: Array<ReviewStatus | SubmissionStatus>;

  constructor(
    public fb: FormBuilder,
    private authService: AuthService,
    public eventsService: EventsService,
    public userService: UserService,
    public preferencesService: PreferencesService,
    public infoService: InfoService,
    public translateService: TranslateService,
    public submissionsService: SubmissionsService,
    public filesService: FilesService
  ) {
    super(fb, submissionsService, filesService, eventsService, infoService);
  }

  ngOnInit() {
    setTimeout(() => {
      if (this.element instanceof Review) {
        this.status = ReviewStatus;
        this.submission = this.element.submission;

        if (!(this.element.assignedBy instanceof User) && this.element.assignedBy) {
          this.userService.getUser(this.element.assignedBy).subscribe(user => {
            (<Review>this.element).assignedBy = user;
          });
        }
      } else {
        this.status = SubmissionStatus;
        this.submission = this.element;
      }

      this.setTrackOfTrackFiles();

      this.statusClass = [
        this.status.toClass(this.element.status),
        this.element instanceof Review ? 'review' : 'submission'
      ];

      this.authService.userIsAdminOrChair(this.submission.event.id).subscribe(isChairOrAdmin => {
        this.userIsChair = isChairOrAdmin;
      });

      this.submissionChanged.subscribe(submission => this.updateSubmission(submission));
    });
  }

  get isReview(): boolean {
    return this.element instanceof Review;
  }

  get styles(): object {
    return {
      compact: this.preferencesService.getPreference('viewMode') === 'Compact',
      default: this.preferencesService.getPreference('viewMode') !== 'Default',
      noIcon: !this.preferencesService.getPreference('statusIcon'),
      paperBackground: this.preferencesService.getPreference('statusColorAsBackgroundColor'),
      [this.statusClass[0]]: true,
      [this.statusClass[1]]: true,
    };
  }

  stopClickPropagation(event): void {
    event.stopPropagation();
  }

  onReviewUpdated(updatedReview: any): void {

    this.element = updatedReview;

    this.statusClass = [
      this.status.toClass(this.element.status),
      this.element instanceof Review ? 'review' : 'submission'
    ];
  }

  public updateSubmission(submission: Submission, setEventAndTrack?: boolean ): void {
    if (setEventAndTrack) {
      // Will update event and track of submission, then emit at submissionChanged.
      // submissionChanged is subscribed in this component, which will call updateSubmission again, without setting event and track.
      this.submissionSetEventAndTrack(submission, this.submissionChanged);
    } else {
      this.submission = submission;
      this.element = this.submission;
      this.statusClass[0] = this.status.toClass(this.element.status);
    }
  }

  fileUploadComplete(fileRule: FileRules, status: number): void {
    if (status === 100) {
      const trackFiles = (<Track>this.submission.track).trackFiles;
      this.verifyAndUpdateStatus(this.submission, fileRule, trackFiles, this.submissionChanged);
    }
  }
}
