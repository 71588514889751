import { Injectable } from '@angular/core';
import { Submission, SubmissionStatus, DeletedSubmission } from '../model/paper';
import { Track } from '../model/track';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { plainToClass, plainToClassFromExist } from 'class-transformer';
import { Environment } from '../environment/environment';
import { forkJoin, Observable, of as ObservableFrom, of, Subject } from 'rxjs';
import { PrintingProblem } from '../model/printing.problem';
import { FilesService } from './files.service';
import { Response } from '../model/response';
import { CacheService } from './cache.service';
import { SubmissionFilter } from '../model/submission.filter';
import { FileRules } from '../model/file.rules';
import { Pagination } from '../model/pagination';
import { DEFAULT_PAGINATION_OPTIONS, SHOW_ALL_PAGINATION_OPTIONS, PaginationOptions } from '../model/pagination.options';
import { Review } from '../model/paper';
import { DiscussionMessage } from '../model/discussionMessage';
import { Rebuttal } from '../model/rebuttal';
import { File } from '../model/file';
import { SubmissionCoverage } from './publication.service';
import { Target } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class SubmissionsService {
  constructor(
    private http: HttpClient,
    private filesService: FilesService,
    private cache: CacheService
  ) { }

  getSubmission(id: number, refreshCache = false, isAdminRoute?: Boolean): Observable<Submission> {
    const params = { params: {} };
    // Flag that, when given, force the load of the files of the submission
    if (isAdminRoute) {
      params.params['isAdminRoute'] = isAdminRoute;
    }

    let foundSubmission = this.cache.getValue(this.cache.submissions, id.toString());
    if (refreshCache) { 
      foundSubmission = undefined;
    }

    if (foundSubmission) {
      return ObservableFrom(foundSubmission);
    } else {
      return this.http.get<Response>(`${Environment.urls.API}/core/submission/${id}/`, params)
        .pipe(map(v => plainToClass(Submission, v.data as Submission)))
        .pipe(map(submission =>
          this.cache
            .setValue(this.cache.submissions, submission.id.toString(), submission)
            .get(submission.id.toString())
        ));
    }
  }

  getUserSubmissions(options: PaginationOptions = DEFAULT_PAGINATION_OPTIONS,
    submFilter: SubmissionFilter = new SubmissionFilter(),
    event?: number, ignoreStatus = false): Observable<Pagination<Submission>> {
    const params = { params: {} };

    if (options.pageNumber) {
      params.params['pageNumber'] = String(options.pageNumber);
    }

    if (options.show) {
      params.params['show'] = String(options.show);
    }

    if (!submFilter.isEmpty()) {
      params.params['orderBy'] = submFilter.orderBy;
      params.params['sortBy'] = submFilter.sortBy;

      if (!ignoreStatus && submFilter.status.length > 0) {
        params.params['status'] = submFilter.status.map(s => s);
      }

      params.params['missingManuscripts'] = submFilter.missingManuscripts;
    }

    if (options.pageSize) {
      params.params['pageSize'] = String(options.pageSize);
    }

    if (event) {
      params.params['event'] = String(event);
    }

    return this.http.get<Response>(`${Environment.urls.API}/core/submission/byUser/`, params)
      .pipe(map(v => plainToClassFromExist(new Pagination<Submission>(Submission), v.data)))
      .pipe(map(v => {
        this.cache.setAll(
          this.cache.submissions,
          v.items.map(submission => ({ key: submission.id.toString(), value: submission }))
        );

        return v;
      }));
  }

  getUserIDSubmissions(options: PaginationOptions = DEFAULT_PAGINATION_OPTIONS,
    submFilter: SubmissionFilter = new SubmissionFilter(),
    event?: number, userId?:number, ignoreStatus = false): Observable<Pagination<Submission>> {
    const params = { params: {} };

    if (options.pageNumber) {
      params.params['pageNumber'] = String(options.pageNumber);
    }

    if (options.show) {
      params.params['show'] = String(options.show);
    }

    if (!submFilter.isEmpty()) {
      params.params['orderBy'] = submFilter.orderBy;
      params.params['sortBy'] = submFilter.sortBy;

      if (!ignoreStatus && submFilter.status.length > 0) {
        params.params['status'] = submFilter.status.map(s => s);
      }

      params.params['missingManuscripts'] = submFilter.missingManuscripts;
    }

    if (options.pageSize) {
      params.params['pageSize'] = String(options.pageSize);
    }

    if (event) {
      params.params['event'] = String(event);
    }

    return this.http.get<Response>(`${Environment.urls.API}/core/submission/byUser/${userId}/`, params)
      .pipe(map(v => plainToClassFromExist(new Pagination<Submission>(Submission), v.data)))
      .pipe(map(v => {
        this.cache.setAll(
          this.cache.submissions,
          v.items.map(submission => ({ key: submission.id.toString(), value: submission }))
        );

        return v;
      }));
  }

  getUserSubmissionsFromEvent(event: number) {
    return this.getUserSubmissions(SHOW_ALL_PAGINATION_OPTIONS, new SubmissionFilter(), event);
  }

  getSessionsSubmissionsBySessionChair(event: number, user: number): Observable<Pagination<Submission>> {
    const params = { params: {} };

    if (event) {
      params.params['event'] = String(event);
    }

    if (user) {
      params.params['user'] = String(user);
    }

    return this.http.get<Response>(`${Environment.urls.API}/core/submission/byUserSessionChair/`, params)
      .pipe(map(v => plainToClassFromExist(new Pagination<Submission>(Submission), v.data)))
      .pipe(map(v => {
        this.cache.setAll(
          this.cache.submissions,
          v.items.map(submission => ({ key: submission.id.toString(), value: submission }))
        );

        return v;
      }));
    }

    getSubmissionsBySession(sessionId: number): Observable<Array<Submission>> {
      return this.http.get<Response>(Environment.urls.API + '/core/submission/bySession/' + sessionId + '/')
      .pipe(map(v => plainToClass(Submission, v.data as Array<Submission>)));
    }

  getUserIDSubmissionsFromEvent(event: number, userId: number) {
    return this.getUserIDSubmissions(SHOW_ALL_PAGINATION_OPTIONS, new SubmissionFilter(), event, userId);
  }

  getSubmissionsByEvent(eventId: number, params: { detailed?, toAssign?, status?, track?, paperGroup? }): Observable<Submission[]> {
    return this.http.get<Response>(Environment.urls.API + '/core/submission/byEvent/' + eventId + '/', { params })
      .pipe(map(v => plainToClass(Submission, v.data.items as Array<Submission>)))
      .pipe(map(submissions => {
        if (params.detailed) {
          submissions.forEach(submission =>
            submission.reviews.forEach(review => {
              (<Review>review).submission = submission;
            })
          );
        }

        if (!params.toAssign) {
        this.cache.setAll(
          this.cache.submissions,
          submissions.map(submission => ({ key: submission.id.toString(), value: submission }))
        );
        }
        return submissions;
      }));
  }

  getSubmissionsByEventFull(eventId: number, params: { detailed?, toAssign?, status?, track?, paperGroup?, reviews? }): Observable<Submission[]> {
    return this.http.get<Response>(Environment.urls.API + '/core/submission/byEvent/' + eventId + '/full/', { params })
      .pipe(map(v => plainToClass(Submission, v.data as Array<Submission>)))
      .pipe(map(submissions => {
        if (params.detailed) {
          submissions.forEach(submission =>
            submission.reviews.forEach(review => {
              (<Review>review).submission = submission;
            })
          );
        }

        if (!params.toAssign) {
        this.cache.setAll(
          this.cache.submissions,
          submissions.map(submission => ({ key: submission.id.toString(), value: submission }))
        );
        }

        return submissions;
      }));
  }

  getSubmissionsByEventMinimum(id: number): Observable<Submission[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/submission/byEvent/${id}/minimum/`, {}).pipe(
      map(v => v.data as Submission[])
    );
  }

  public getReportPrintingProblem(submissionId: number): Observable<Array<PrintingProblem>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/submission/${submissionId}/report/problem/printing/`)
      .pipe(map(v => plainToClass(PrintingProblem, v.data as Array<PrintingProblem>)));
  }

  getSubmissionReviewersByInterest(submissionId: number): Observable<Submission> {
    return this.http
      .get<Response>(`${Environment.urls.API}/core/submission/${submissionId}/reviewersByInterest/`)
      .pipe(map(value => plainToClass(Submission, value.data as Submission)));
  }

  getAllSubmissionsByEvent(eventId: number): Observable<Array<Submission>> {
    return this.http.get<Response>(Environment.urls.API + '/core/submission/byEvent/' + eventId + '/all/')
      .pipe(map(v => plainToClass(Submission, v.data as Array<Submission>)));
  }

  getAllSubmissionsWithoutPaperGroupByEvent(eventId: number): Observable<Array<Submission>> {
    return this.getAllSubmissionsByEvent(eventId).pipe(map(v => v.filter(submission => !submission.paperGroup)));
  }

  getAllSubmissionsWithoutTPCGroupByEvent(eventId: number): Observable<Array<Submission>> {
    return this.getAllSubmissionsByEvent(eventId).pipe(map(v => v.filter(submission => !submission.tpcGroup)));
  }

  getPagedSubmissionsByEvent(
    eventId: number,
    options: PaginationOptions = DEFAULT_PAGINATION_OPTIONS,
    subFilter: SubmissionFilter = new SubmissionFilter()
  ): Observable<Pagination<Submission>> {
    const params = { params: {} };

    if (options.pageNumber) {
      params.params['pageNumber'] = String(options.pageNumber);
    }

    if (options.pageSize) {
      params.params['pageSize'] = String(options.pageSize);
    }

    if (options.show) {
      params.params['show'] = String(options.show);
    }

    if (!subFilter.isEmpty()) {
      params.params['orderBy'] = subFilter.orderBy;
      params.params['sortBy'] = subFilter.sortBy;

      if (subFilter.status.length > 0) {
        params.params['status'] = subFilter.status.map(s => s);
      }
    }

    return this.http.get<Response>(`${Environment.urls.API}/core/submission/byEvent/${eventId}/`, params)
      .pipe(map(v => plainToClassFromExist(new Pagination<Submission>(Submission), v.data)))
      .pipe(map(v => {
        this.cache.setAll(
          this.cache.submissions,
          v.items.map(submission => ({ key: submission.id.toString(), value: submission }))
        );

        return v;
      }));
  }

  createSubmission(data: any): Observable<Submission> {
    return this.http.post<Response>(`${Environment.urls.API}/core/submission/`, data)
      .pipe(map(v => plainToClass(Submission, v.data as Submission)))
      .pipe(map(submission =>
        this.cache
          .setValue(this.cache.submissions, submission.id.toString(), submission)
          .get(submission.id.toString())
      ));
  }

  editSubmission(data: any, id: number): Observable<Submission> {
    return this.http.put<Response>(`${Environment.urls.API}/core/submission/${id}/`, data)
      .pipe(map(v => plainToClass(Submission, v.data as Submission)))
      .pipe(map(submission =>
        this.cache
          .setValue(this.cache.submissions, submission.id.toString(), submission)
          .get(submission.id.toString())
      ));
  }

  editMinimumSubmission(data: any, id: number): Observable<Submission> {
    return this.http.put<Response>(`${Environment.urls.API}/core/submission/minimum/${id}/`, data)
      .pipe(map(v => plainToClass(Submission, v.data as Submission)))
      .pipe(map(submission =>
        this.cache
          .setValue(this.cache.submissions, submission.id.toString(), submission)
          .get(submission.id.toString())
      ));
  }

  changeTracks(data: {submission_id: number, target_track_id: number, swap: {}}): Observable<Submission> {
    return this.http.patch<Response>(`${Environment.urls.API}/core/trackFile/changeTracks/`, data)
      .pipe(map(v => plainToClass(Submission, v.data as Submission)));
  }

  submitFiles(files: any, submissionID: number, track: Track): Observable<Array<{ fileRule: FileRules, status: number }>> {
    // return: do forkJoin, um array de {
    //  FileRules,
    //  status: number, -1 => equivale a erro no upload daquele tipo de arquivo.
    // }
    const uploadStatus: Array<{fileRule: FileRules, status: number}> = [];
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const fileRule = track.trackFiles.find(e => e.name === key);

        uploadStatus.push({ fileRule, status: 0 });
      }
    }

    const subj = new Subject<Array<{ fileRule: FileRules, status: number }>>();
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key][0];
        const fileRule = track.trackFiles.find(e => e.name === key);

        if (fileRule) {
          this.filesService.uploadSubmissionFile(file, submissionID, fileRule).pipe(
            catchError(error => ObservableFrom(-1)),
          ).subscribe(v => {
            uploadStatus.find(u => u.fileRule === fileRule).status = v;

            subj.next(uploadStatus);
            if (v === 100) {
              this.submitFilesCompleted(subj, uploadStatus);
            }
          }, () => {}, () => {
            this.submitFilesCompleted(subj, uploadStatus);
          });
        } else {
          console.log('rule not found');
        }
      }
    }

    return subj.asObservable();
  }

  // When a file has finished uploading, check if all files have completed.
  private submitFilesCompleted(
    subject: Subject<Array<{ fileRule: FileRules, status: number }>>,
    uploadStatus: Array<{ fileRule: FileRules, status: number }>
  ) {
    const notAllUploadsComplete = !!uploadStatus.find(u => u.status !== 100);
    if (!notAllUploadsComplete) {
      subject.complete();
    }
  }

  public getSubmissionsToClaim(eventId: number, userId: number) {
    return this.http.get<Response>(`${Environment.urls.API}/core/claimReview/${eventId}/${userId}/`)
      .pipe(map(v => plainToClass(Submission, v.data as Array<Submission>)));
  }

  public updateClaimSubmissions(eventId: number, userId: number, submissionsId: number[]) {
    return this.http.put<Response>(`${Environment.urls.API}/core/claimReview/${eventId}/${userId}/`, { submissionsId })
      .pipe(map(v => plainToClass(Submission, v.data as Array<Submission>)));
  }

  public assignSubmissionStatus(
    submissionsIds: Array<number>, status: SubmissionStatus, event: number
  ): Observable<{ [submission: number]: boolean }> {
    return this.http.put<Response>(`${Environment.urls.API}/core/submissionList/AcceptOrReject/${event}/`, { submissionsIds, status })
      .pipe(map(v => v.data));
  }

  public withdrawSubmission(submission: number, withdrawReason: string): Observable<Submission> {
    return this.http.put<Response>(`${Environment.urls.API}/core/submission/${submission}/withdraw/`, { withdrawReason })
      .pipe(map(v => plainToClass(Submission, v.data as Submission)));
  }

  public deleteSubmission(submission: number, deleteReason: string): Observable<Submission> {
    return this.http.put<Response>(`${Environment.urls.API}/core/submission/${submission}/deleteSubmission/`, { deleteReason })
      .pipe(map(v => plainToClass(Submission, v.data as Submission)));
  }

  public permanentlyDeleteSubmission(submission: number, deleteReason: string): Observable<Submission> {
    return this.http.put<Response>(`${Environment.urls.API}/core/submission/${submission}/permanentlydeleteSubmission/`, { deleteReason })
      .pipe(map(v => plainToClass(Submission, v.data as Submission)));
  }

  public recoverySubmission(submission: number, previousStatus: string): Observable<Submission> {
    const params = {'previousStatus': previousStatus};
    return this.http.put<Response>(`${Environment.urls.API}/core/submission/${submission}/recoverySubmission/`, params)
      .pipe(map(v => plainToClass(Submission, v.data as Submission)));
  }
 
  public getDeletedSubmissions(params?: { event }): Observable<Array<DeletedSubmission>> {
    return this.http.get<Response>(`${Environment.urls.API}/core/submission/deleteSubmissions/`, { params })
      .pipe(map(v => plainToClass(DeletedSubmission, v.data as Array<DeletedSubmission>)));
  }

  public getDiscussionMessages(submission_id: number): Observable<DiscussionMessage[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/submission/${submission_id}/discussionMessages/`)
      .pipe(map(v => plainToClass(DiscussionMessage, v.data as DiscussionMessage[])));
  }

  public postDiscussionMessages(submission_id: number, user_id: number, content: string): Observable<DiscussionMessage[]> {
    const params = {
      'sender': user_id,
      'content': content
    };
    return this.http.post<Response>(`${Environment.urls.API}/core/submission/${submission_id}/discussionMessages/`, params)
      .pipe(map(v => plainToClass(DiscussionMessage, v.data as DiscussionMessage[])));
  }

  public deleteDiscussionMessage(submission_id: number, message_id: number): Observable<DiscussionMessage[]> {
    return this.http.delete<Response>(`${Environment.urls.API}/core/submission/${submission_id}/discussionMessages/${message_id}/`)
      .pipe(map(v => plainToClass(DiscussionMessage, v.data as DiscussionMessage[])));
  }


  public getRebuttal(submission: number): Observable<Rebuttal> {
    return this.http.get<Response>(`${Environment.urls.API}/core/submission/${submission}/rebuttal/`)
      .pipe(map(v => plainToClass(Rebuttal, v.data as Rebuttal)));
  }

  public writeRebuttal(submission: number, content: string): Observable<Rebuttal> {
    return this.http.post<Response>(`${Environment.urls.API}/core/submission/${submission}/rebuttal/`, { content })
      .pipe(map(v => plainToClass(Rebuttal, v.data as Rebuttal)));
  }

  public getEventBibtex(event: number, status: SubmissionStatus[], tracks: number[]): Observable<string[]> {
    return this.http.post<Response>(
      `${Environment.urls.API}/core/submission/byEvent/${event}/all/bibtex/`, {
      status, tracks
    }).pipe(map(v => v.data as string[]));
  }

  public deleteSubmissionFile(file: File, submission: Submission, updateSubmission = true): Observable<Submission> {
    const tasks: Array<Observable<Response> | Observable<Submission>> = [
      this.filesService.deleteFile(file.id)
    ];

    if (submission.status === SubmissionStatus.ACTIVE && file.trackFile.required) {
      submission.status = SubmissionStatus.PENDING;

      if (updateSubmission) {
        tasks.push(this.editSubmission({ status: SubmissionStatus.PENDING }, submission.id));
      }
    }

    return forkJoin(tasks).pipe(map(([ deleteFileResponse, editedSubmission ]: [ Response, Submission ]) => {
      if (updateSubmission) {
        return editedSubmission;
      }
      return submission;
    }));
  }

  getPublicationInfo(submission: number): Observable<SubmissionCoverage[]> {
    return this.http.get<Response>(`${Environment.urls.API}/core/submission/${submission}/publication/`, {}).pipe(
      map(v => v.data as SubmissionCoverage[])
    );
  }

  updateSubmissionTrackFiles(fileIds: number[]): Observable<Object> {
    const params = {
      'file_ids': fileIds,
    };

    return this.http.post<Response>(`${Environment.urls.API}/core/submission/updateSubmissionTrackFiles/`, params).pipe(
      map(v => v.data as Object)
    );
  }

  public getTrackFilesMapping(target_track_id, source_track_id, user_id) : Observable<Object> {
    
    return this.http.get<Response>(`${Environment.urls.API}/core/submission/updateTrackFilesMapping/${target_track_id}/${source_track_id}/${user_id}`, { })
    .pipe(map(v => v.data as Object));
  }

  public updateTrackFilesMapping(user_id, track_target_id, track_source_id, source_file_id, target_file_id) : Observable<Object> {

    return this.http.post<Response>(`${Environment.urls.API}/core/submission/updateTrackFilesMapping/`, {
      user_id,
      track_source_id,
      track_target_id,
      source_file_id,
      target_file_id
    })
    .pipe(map(v => v.data as Object));
  }
}
